import React, { useState } from "react";
import { Link } from "react-router-dom";

export const Menu = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);

  const handleClick = () => {
    setShowDropdown(false);
    setShowDropdown1(false);
    setShowDropdown2(false);
    setShowDropdown3(false);
  };

  const toggleDropown = (number) => {
    switch (number) {
      case 0:
        setShowDropdown(!showDropdown);
        break;
      case 1:
        setShowDropdown1(!showDropdown1);
        break;
      case 2:
        setShowDropdown2(!showDropdown2);
        break;
      case 3:
        setShowDropdown3(!showDropdown3);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <ul className="navbar-nav mr-auto container d-flex justify-content-around align-items-center">
        <li className="nav-item">
          <Link className="nav-link" to="/" onClick={handleClick}>
            Início
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/quem-somos/" onClick={handleClick}>
            Quem somos
          </Link>
        </li>
        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(0)}
          onMouseLeave={() => toggleDropown(0)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Registro Civil
          </Link>
          {showDropdown && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link
                className="dropdown-item"
                to="/servicos/certidoes/"
                onClick={handleClick}
              >
                Certidões
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/nascimento/"
                onClick={handleClick}
              >
                Nascimento
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/retificacao-de-registro-civil/"
                onClick={handleClick}
              >
                Retificação de Registro Civil
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/casamento/"
                onClick={handleClick}
              >
                Casamento
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/obito/"
                onClick={handleClick}
              >
                Óbito
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/reconhecimento-de-paternidade/"
                onClick={handleClick}
              >
                Reconhecimento de Paternidade
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/edital-de-proclamas/"
                onClick={handleClick}
              >
                Edital de Proclamas
              </Link>
            </div>
          )}
        </li>

        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(1)}
          onMouseLeave={() => toggleDropown(1)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Tabelionato de Notas
          </Link>
          {showDropdown1 && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link
                className="dropdown-item"
                to="/servicos/e-notariado/"
                onClick={handleClick}
              >
                E-Notariado
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/escritura-publica/"
                onClick={handleClick}
              >
                Escritura pública
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/escritura-publica-de-doacao/"
                onClick={handleClick}
              >
                Escritura pública de doação
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/procuracao/"
                onClick={handleClick}
              >
                Procuração
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/apostilamento-haia/"
                onClick={handleClick}
              >
                Apostilamento de Haia
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/declaracao-de-uniao-estavel/"
                onClick={handleClick}
              >
                Declaração de união estável
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/ata-notarial/"
                onClick={handleClick}
              >
                Ata Notarial
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/ata-notarial-usucapiao/"
                onClick={handleClick}
              >
                Ata Notarial de Usucapião
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/autenticacao/"
                onClick={handleClick}
              >
                Autenticação
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/reconhecimento-de-firma/"
                onClick={handleClick}
              >
                Reconhecimento de Firma
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/divorcio/"
                onClick={handleClick}
              >
                Divórcio
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/revogacao-de-procuracao/"
                onClick={handleClick}
              >
                Revogação de Procuração
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/inventario/"
                onClick={handleClick}
              >
                Inventário
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/testamento/"
                onClick={handleClick}
              >
                Testamento
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/malote-virtual/"
                onClick={handleClick}
              >
                Malote Virtual
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/emancipacao/"
                onClick={handleClick}
              >
                Emancipação
              </Link>
              <Link
                className="dropdown-item"
                to="/servicos/substabelecimento/"
                onClick={handleClick}
              >
                Substabelecimento
              </Link>
            </div>
          )}
        </li>
        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(2)}
          onMouseLeave={() => toggleDropown(2)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Informações
          </Link>
          {showDropdown2 && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link
                className="dropdown-item"
                to="/modelos-requerimentos/"
                onClick={handleClick}
              >
                Modelos de Requerimentos
              </Link>

              <Link
                className="dropdown-item"
                to="/custas-e-emolumentos/"
                onClick={handleClick}
              >
                Custas e Emolumentos
              </Link>

              <Link
                className="dropdown-item"
                to="/prazos/"
                onClick={handleClick}
              >
                Prazos dos serviços
              </Link>
            </div>
          )}
        </li>

        <li
          className="nav-item dropdown"
          onMouseEnter={() => toggleDropown(3)}
          onMouseLeave={() => toggleDropown(3)}
        >
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="bi bi-shield-lock-fill"></i> LGPD
          </Link>
          {showDropdown3 && (
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link
                className="dropdown-item"
                to="https://iuryflores.com.br/3rcgo/AvisoGeraldePrivacidadeeCookies.pdf"
                target="_blank"
                onClick={handleClick}
              >
                Aviso de Privacidade
              </Link>
              <Link
                className="dropdown-item"
                to="https://iuryflores.com.br/3rcgo/3. Termo de Consentimento e Aviso de Privacidade para Candidatos.pdf"
                target="_blank"
                onClick={handleClick}
              >
                Aviso de Privacidade para Candidatos
              </Link>
              <Link
                className="dropdown-item"
                to="/solicitacao-dados/"
                onClick={handleClick}
              >
                Solicitação de Dados
              </Link>
            </div>
          )}
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/fale-conosco/" onClick={handleClick}>
            Fale Conosco
          </Link>
        </li>
      </ul>
    </div>
  );
};
