import React from "react";

export const Nascimento = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>NASCIMENTO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              Todos os nascimentos ocorridos no território nacional devem ser
              levados a registro.
            </p>
            <p>
              <strong>Local</strong>
            </p>
            <p>
              O registro deve ser realizado no local onde tiver ocorrido o parto
              ou no lugar da residência dos pais.
            </p>
            <p>
              <strong>Prazo</strong>
            </p>
            <p>
              Até 15 dias após o nascimento de uma criança, os pais ou
              responsáveis deverão comparecer ao serviço de registro civil com a
              documentação necessária. Caso a mãe seja a declarante, o prazo é
              prorrogado por mais 45 dias.
            </p>
            <p>
              A lei prevê, ainda, outra hipótese de ampliação do prazo: quando a
              distância entre o lugar de parto ou domicílio for maior de 30 km
              da sede da serventia. Nesse caso, o prazo é prorrogado em até três
              meses (artigo 50 da Lei 6.016/1973). Após o decurso do prazo legal
              de registro, será competente apenas a serventia do local da
              residência do interessado.
            </p>
            <p>
              <strong>Documentação</strong>
            </p>
            <p>
              Os seguintes documentos, desde que originais, são aceitos como
              identificação do declarante do registro de nascimento:
            </p>
            <p>– Carteira de Identidade</p>
            <p>
              – Carteira de Identidade Profissional (advogado, médico,
              contadores, etc…)
            </p>
            <p>– Carteira Nacional de Habilitação</p>
            <p>
              – Passaporte&nbsp; (Desde que contenha a filiação. Em caso
              negativo, deverá ser apresentada a certidão de nascimento ou de
              casamento traduzida).
            </p>
            <p>
              <strong>
                <u>Observação:</u>
              </strong>{" "}
              Não serão aceitos como identificação: as Carteiras de Identidade
              Funcional ou Passes Livre.
            </p>
            <p>&nbsp;</p>

            <p>
              <strong>Pais Solteiros devem apresentar:</strong>
            </p>
            <p>– Documento de Identificação do(a) declarante</p>
            <p>– CPF do(a) declarante</p>
            <p>– Documento de Identificação da mãe</p>
            <p>– CPF da mãe</p>
            <p>
              – DNV (Declaração de Nascido Vivo) – via amarela emitida pelo
              hospital/maternidade
            </p>

            <p>
              <strong>
                <u>Observação</u>
              </strong>
              : Em caso de pais solteiros: a criança somente poderá ser
              registrada em nome do pai se o mesmo estiver presente no ato do
              registro.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Pais Casados devem apresentar:</strong>
            </p>

            <p>– Certidão de Casamento original</p>
            <p>– Documento de Identificação do(a) declarante</p>
            <p>– CPF do(a) declarante</p>
            <p>– Documento de Identificação dos pais (pai + mãe)</p>
            <p>– CPF dos pais (pai + mãe)</p>
            <p>
              – DNV (Declaração de Nascido Vivo) – via amarela emitida pelo
              hospital/maternidade
            </p>
            <p>&nbsp;</p>
            <p>
              Qualquer das pessoas abaixo podem declarar o registro de
              nascimento, sem necessidade dos demais comparecer:
            </p>
            <p>&nbsp;</p>
            <p>– Pai</p>
            <p>– Mãe</p>
            <p>– Avós da criança</p>
            <p>&nbsp;</p>

            <p>
              <strong>Pais menores de 16 anos devem apresentar:</strong>
            </p>

            <p>– Documento de Identificação do(a) declarante</p>
            <p>– CPF do(a) declarante</p>
            <p>– Documento de Identificação da mãe</p>
            <p>– CPF da mãe</p>
            <p>
              – DNV (Declaração de Nascido Vivo) – via amarela emitida pelo
              hospital/maternidade
            </p>
            <p>
              Mãe menor de 16 anos – comparecer acompanhada por um dos pais (pai
              ou mãe) ou representante legal.
            </p>
            <p>Pai menor de 16 anos – somente com autorização judicial.</p>
            <p>&nbsp;</p>
            <p>
              <strong>Pai representado por procuração deve apresentar:</strong>
            </p>

            <p>– Documento de Identificação do(a) procurador(a)</p>
            <p>– CPF do(a) procurador(a)</p>
            <p>– Documento de Identificação da mãe</p>
            <p>– CPF da mãe</p>
            <p>
              – DNV (Declaração de Nascido Vivo) – via amarela emitida pelo
              hospital/maternidade
            </p>
            <p>&nbsp;</p>
            <p>
              A procuração deverá ser pública e específica para o registro de
              nascimento, com as seguintes informações:
            </p>
            <p>– Declaração expressa de paternidade</p>
            <p>– Nome dos avós paternos</p>
            <p>– Nome completo do(a) registrado(a)</p>
            <p>– Data de Nascimento do(a) registrado(a)</p>
            <p>
              – Número da DNV (Declaração amarela emitida pelo
              hospital/maternidade)
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
