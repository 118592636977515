import React from "react";
import { Link } from "react-router-dom";

const Requerimentos = ({ title, category, desc, link }) => {
  return (
    <div className="servico-div">
      <div>
        <h3>{title}</h3>
        <small>
          <strong>{category}</strong>
        </small>
        <span>{desc}</span>
      </div>
      <Link to={link} target="_blank">
        <div className="botao animate">visualizar</div>
      </Link>
    </div>
  );
};

export default Requerimentos;
