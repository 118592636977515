import React from "react";
import { Link } from "react-router-dom";

export const Inventario = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>INVETÁRIO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              O inventário é o procedimento utilizado para apuração dos bens,
              direitos e dívidas do falecido.
            </p>
            <p>
              Com a partilha é instrumentalizada a transferência da propriedade
              dos bens aos herdeiros.
            </p>
            <p>
              A Lei 11.441/07 facilitou a vida do cidadão e desburocratizou o
              procedimento de inventário ao permitir a realização desse ato em
              cartório, por meio de escritura pública, de forma rápida, simples
              e segura.
            </p>
            <p>
              <b>Atenção:</b> Mesmo que a pessoa tenha falecido antes da Lei
              11.441/07, também é possível fazer o inventário por escritura
              pública, se preenchidos os requisitos da lei.
            </p>
            <hr />{" "}
            <h5>
              Quais são os requisitos para realizar um inventário em cartório?
            </h5>
            <p>
              Para que o inventário possa ser feito em cartório, é necessário
              observar os seguintes requisitos:
            </p>
             <ul className="fs-6-9">
              <li>todos os herdeiros devem ser maiores e capazes;</li>
              <li>
                deve haver consenso entre os herdeiros quanto à partilha dos
                bens;
              </li>
              <li>o falecido não pode ter deixado testamento;</li>
              <li>
                a escritura deve contar com a participação de um advogado.
              </li>
            </ul>
            <p>
              Se houver filhos menores, incapazes ou se o falecido tiver deixado
              testamento, o inventário deverá ser feito judicialmente.
            </p>
            <p>
              Havendo filhos emancipados, o inventário pode ser feito em
              cartório.
            </p>
            <p>
              A escritura de inventário não depende de homologação judicial.
            </p>
            <p>
              Para transferência dos bens para o nome dos herdeiros é necessário
              apresentar a escritura de inventário para registro no Cartório de
              Registro de Imóveis (bens imóveis), no DETRAN (veículos), no
              Cartório de Registro Civil de Pessoas Jurídicas ou na Junta
              Comercial (sociedades), nos Bancos (contas bancárias), etc.
            </p>
            <p>
              <b>Atenção:</b> Caso exista inventário judicial em andamento, os
              herdeiros podem, a qualquer tempo, desistir do processo e optar
              pela escritura de inventário extrajudicial.
            </p>
            <hr />{" "}
            <h5>
              Qual é o cartório competente para realização de um inventário?
            </h5>
            <p>
              O inventário extrajudicial pode ser feito em qualquer Cartório de
              Notas, independentemente do domicílio das partes, do local de
              situação dos bens ou do local do óbito do falecido.
            </p>
            <p>
              Não se aplicam as regras de competência do Código de Processo
              Civil ao inventário extrajudicial.
            </p>
            <p>
              <b>Atenção:</b> As partes podem escolher livremente o tabelião de
              notas de sua confiança.
            </p>
            <hr />{" "}
            <h5>
              Quais são os documentos necessários para fazer um inventário em
              cartório?
            </h5>
            <p>
              Para lavratura da escritura de inventário são necessários os
              seguintes documentos:
            </p>
             <ul className="fs-6-9">
              <li>Documentos do falecido;</li>
              <li>
                RG, CPF, certidão de óbito, certidão de casamento (atualizada
                até 90 dias) e escritura de pacto antenupcial (se houver);
              </li>
              <li>
                Certidão comprobatória de inexistência de testamento expedida
                pelo Colégio Notarial do Brasil;
              </li>
              <li>
                Certidão negativa da Receita Federal e Procuradoria Geral da
                Fazenda Nacional;
              </li>
              <li>Documentos do cônjuge, herdeiros e respectivos cônjuges;</li>
              <li>
                RG e CPF, informação sobre profissão, endereço, certidão de
                nascimento, certidão de casamento dos cônjuges (atualizada até
                90 dias);
              </li>
              <li>Documentos do advogado;</li>
              <li>
                Carteira da OAB, informação sobre estado civil e endereço do
                advogado;
              </li>
              <li>
                Informações sobre bens, dívidas e obrigações, descrição da
                partilha e pagamento do ITCMD;
              </li>
              <li>
                imóveis urbanos: Certidão de Ônus expedida pelo Cartório de
                Registro de Imóveis, atualizada até 30 dias (até o momento da
                lavratura da escritura), carnê de IPTU, certidão negativa de
                tributos municipais incidentes sobre imóveis, declaração de
                quitação de débitos condominiais, bem como Certidão de Inteiro
                Teor do Imóvel, atualizada até 30 dias (até o momento da
                lavratura da escritura);
              </li>
              <li>
                imóveis rurais: certidão de ônus expedida pelo Cartório de
                Registro de Imóveis (atualizada até 30 dias), cópia autenticada
                da declaração de ITR dos últimos 5 (cinco) anos ou Certidão
                Negativa de Débitos de Imóvel Rural emitida pela Secretaria da
                Receita Federal – Ministério da Fazenda, CCIR – Certificado de
                Cadastro de Imóvel Rural expedido pelo INCRA;
              </li>
              <li>
                bens móveis: documento de veículos, extratos bancários, certidão
                da junta comercial ou do cartório de registro civil de pessoas
                jurídicas, notas fiscais de bens e jóias, etc.
              </li>
            </ul>
            <p>
              <b>Atenção:</b> O pagamento do imposto de transmissão causa mortis
              (ITCMD) deve ser efetuado em até 180 dias da data do óbito.
            </p>
            <h6>
              É necessário contratar advogado para fazer o inventário em
              cartório?
            </h6>
            <p>
              A lei exige a participação de um advogado como assistente jurídico
              das partes nas escrituras de inventário.
            </p>
            <p>
              O tabelião, assim como o juiz, é um profissional do direito que
              presta concurso público, e age com imparcialidade na orientação
              jurídica das partes. O advogado comparece ao ato na defesa dos
              interesses de seus clientes.
            </p>
            <p>
              Os herdeiros podem ter advogados distintos ou um só advogado para
              todos.
            </p>
            <p>
              O advogado deverá assinar a escritura juntamente com as partes
              envolvidas. Não é necessário apresentar petição ou procuração, uma
              vez que esta é outorgada pelos interessados na própria escritura
              de inventário.
            </p>
            <p>
              <b>Atenção:</b> Se um dos herdeiros for advogado, ele pode atuar
              também na qualidade de assistente jurídico na escritura.
            </p>
            <hr />{" "}
            <h5>
              É possível ser representado por procurador na escritura de
              inventário?
            </h5>
            <p>
              Caso o interessado não possa comparecer pessoalmente ao cartório
              para assinar a escritura de inventário, ele poderá nomear um
              procurador por meio de procuração pública, feita em cartório de
              notas, com poderes específicos para essa finalidade.
            </p>
            <h6>O que é inventário negativo?</h6>
            <p>
              O inventário negativo é utilizado para comprovar a inexistência de
              bens a partilhar.
            </p>
            <p>
              Ele é necessário caso os herdeiros queiram comprovar que o
              falecido deixou apenas dívidas, ou caso o cônjuge sobrevivente
              queira escolher livremente o regime de bens de um novo casamento.
            </p>
            <h6>O que é sobrepartilha?</h6>
            <p>
              Se após o encerramento do inventário os herdeiros descobrirem que
              algum bem não foi inventariado, é possível realizar a
              sobrepartilha por meio de escritura pública, observados os
              seguintes requisitos: (a) herdeiros maiores e capazes; (b)
              consenso entre os herdeiros quanto à partilha dos bens; (c)
              inexistência de testamento; (d) participação de um advogado.
            </p>
            <p>
              A sobrepartilha pode ser feita extrajudicialmente, a qualquer
              tempo, ainda que a partilha anterior tenha sido feita
              judicialmente e ainda que os herdeiros, hoje maiores, fossem
              menores ou incapazes ao tempo da partilha anterior.
            </p>
            <h6>Pode ser reconhecida a união estável em inventário?</h6>
            <p>
              Se o falecido vivia em união estável, os herdeiros podem
              reconhecer a existência dessa união na escritura de inventário.
            </p>
            <p>
              Se o companheiro for o único herdeiro ou se houver conflito entre
              ele e os demais herdeiros, o reconhecimento da união estável deve
              ser feito judicialmente.
            </p>
            <p>
              É reconhecida como entidade familiar a união estável entre o homem
              e a mulher, configurada na convivência pública, contínua e
              duradoura com o objetivo de constituição de família. O Supremo
              Tribunal Federal atribuiu às uniões homoafetivas os mesmos efeitos
              da união estável heteroafetiva.
            </p>
            <h6>É possível renunciar à herança?</h6>
            <p>
              Se o herdeiro não tiver interesse em receber a herança, a renúncia
              pode ser feita por escritura pública.
            </p>
            <h6>
              É possível fazer em cartório o inventário de bens situados no
              exterior?
            </h6>
            <p>
              Se o falecido deixar bens situados no exterior não é possível
              fazer o inventário por escritura pública.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="https://juridicocerto.com/p/paulohgadv/artigos/inventario-extrajudicial-o-que-precisa-saber-2002"
                target="_blank"
              >
                https://juridicocerto.com/p/paulohgadv/artigos/inventario-extrajudicial-o-que-precisa-saber-2002
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
