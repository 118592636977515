import React from "react";

export const ReconhecimentoFirma = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>RECONHECIMENTO DE FIRMA</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              Ao reconhecer a firma de alguém, declara-se que a assinatura
              constante no documento confere com o padrão de assinatura do
              signatário que se encontra arquivado no cartório.
            </p>
            <p>
              Para a abertura da ficha-padrão (cartão de assinatura), é
              obrigatória a apresentação do documento de identidade original.
              Admitem-se como documento de identificação:
            </p>
            <p>Registro Geral</p>
            <p>
              Carteira Nacional de Habilitação, modelo atual, instituído pela
              Lei número 9.503/97.
            </p>
            <p>
              carteira de exercício profissional expedida pelos entes criados
              por Lei Federal, nos termos da Lei nº 6.206/75
            </p>
            <p>
              passaporte que, na hipótese de estrangeiro, deverá estar com prazo
              do visto não expirado
            </p>

            <p>
              <strong>Observação:</strong> Não serão admitidos documentos
              replastificados.
            </p>
            <p> </p>
            <p>
              <strong>Modalidades</strong>
            </p>
            <p>
              <u>Reconhecimento de firma por semelhança:</u> Declara-se que a
              assinatura presente em determinado documento confere com o padrão
              da sua assinatura constante de sua ficha-padrão (cartão de
              assinatura).
            </p>
            <p>
              <u>Reconhecimento de firma autêntica/verdadeira:</u> A assinatura
              é lançada na presença do oficial do cartório, que menciona essa
              circunstância no ato do reconhecimento.
            </p>
            <p>
              Para o reconhecimento de firma, poderá ser exigida a presença do
              signatário ou a apresentação do seu documento de identidade.
            </p>
            <p>
              É vedado o reconhecimento de firma em documentos sem data,
              incompletos ou que contenham, no contexto, espaços em branco.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
