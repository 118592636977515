import React from "react";
import { Link } from "react-router-dom";
import logoIury from "../imgs/iury.png";
import logo from "../imgs/logo.png";

export const Footer = () => {
  return (
    <div style={{ backgroundColor: "#5a84474d", color: "#5a8447" }}>
      <div className="container w-100 px-3">
        <div className="footer-ri row  ">
          <ul className="d-flex  col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <p>Acesso Rápido</p>
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to="/quem-somos/">Quem somos</Link>
            </li>
            <li>
              <Link to="/modelos-requerimentos/">Modelos de requerimentos</Link>
            </li>
            <li>
              <Link to="/custas-e-emolumentos/">Custas e Emolumentos</Link>
            </li>
            <li>
              <Link
                to="https://iuryflores.com.br/3rcgo/AvisoGeraldePrivacidadeeCookies.pdf"
                target="_blank"
              >
                Aviso de Privacidade
              </Link>
            </li>
            <li>
              <Link
                to="https://iuryflores.com.br/3rcgo/3. Termo de Consentimento e Aviso de Privacidade para Candidatos.pdf"
                target="_blank"
              >
                Aviso de Privacidade para Candidatos
              </Link>
            </li>
            <li>
              <Link to="/solicitacao-dados/">Solicitação de Dados</Link>
            </li>
            <li>
              <Link to="/fale-conosco/">Fale conosco</Link>
            </li>
          </ul>
          <ul className="d-flex col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <p>Contato</p>
            <li>Telefone: (62) 3225-1847 | (62) 3229-3097</li>
            <li>WhatsApp: (62) 9 9848-3097</li>
            <li>Email: cartorio@terceirocartoriogo.com.br</li>
          </ul>
          <ul className="d-flex col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <p>Localização</p>
            <li>Rua 7, número 369</li>
            <li>Setor Central</li>
            <li>Goiânia - GO</li>
            <li>CEP: 74.023-020</li>
          </ul>
          <ul className="d-flex  col-sm-6 col-md-4 col-xl-3 flex-column align-items-start">
            <img
              src={logo}
              style={{ filter: "invert(0)" }}
              alt="Logo Cartório"
            />
          </ul>
        </div>
      </div>
      <footer className="d-flex flex-wrap justify-content-around align-items-center  border-top no-mobile">
        <Link to="https://iuryflores.com.br" target="_blank" className="d-flex align-items-center">
          <img src={logoIury} alt="Logotipo 1RIGO" className="mx-3"/>
          {" "}
          Desenvolvido por Iury Flores
        </Link>{" "}
        <div className="logo">
          © 2023 | Todos os direitos reservados - 3º Registro Civil e
          Tabelionato de Notas de Goiânia-GO
        </div>
      </footer>
    </div>
  );
};
