import React from "react";

export const RetificacaoRc = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>RETIFICAÇÃO DE REGISTRO CIVIL</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              Os assentos de registro civil que apresentem informações
              equivocadas podem ser retificados pelas vias jurisdicional ou
              administrativa. No primeiro caso, o interessado será representado
              por advogado ou defensor público em processo judicial (art. 109 da
              LRP). Na via administrativa, o erro é retificado diretamente no
              cartório, não se exigindo a constituição de advogado. Este
              procedimento, que dispensa prévia autorização judicial ou
              manifestação do Ministério Público, inicia-se com uma petição
              assinada pelo interessado, representante legal ou procurador, para
              retificar o assento de registro civil exclusivamente nos casos de:
            </p>
            <p>
              I – erros que não exijam qualquer indagação para a constatação
              imediata de necessidade de sua correção;
            </p>
            <p>
              II – erro na transposição dos elementos constantes em ordens e
              mandados judiciais, termos ou requerimentos, bem como outros
              títulos a serem registrados, averbados ou anotados, e o documento
              utilizado para a referida averbação e/ou retificação ficará
              arquivado no registro no cartório;
            </p>
            <p>
              III – inexatidão da ordem cronológica e sucessiva referente à
              numeração do livro, da folha, da página, do termo, bem como da
              data do registro;
            </p>
            <p>
              IV – ausência de indicação do Município relativo ao nascimento ou
              naturalidade do registrado, nas hipóteses em que existir descrição
              precisa do endereço do local do nascimento;
            </p>
            <p>
              V – elevação de Distrito a Município ou alteração de suas
              nomenclaturas por força de lei (Art. 110, Lei 6.015/73).
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
