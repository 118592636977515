import React from "react";
import { Link } from "react-router-dom";

const Servicos = ({ title, desc, link }) => {
  return (
    <div className="servico-div">
      <div>
        <h3>{title}</h3>
        <span>{desc}</span>
      </div>
      <Link to={link}>
        <div className="botao animate">saiba mais</div>
      </Link>
    </div>
  );
};

export default Servicos;
