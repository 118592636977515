import React from "react";
import { Link } from "react-router-dom";

export const Substabelecimento = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>SUBSTABELECIMENTO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>
              O que é substabelecimento de procuração e quais os seus efeitos?
            </h5>
            <p>
              Substabelecimento é o instrumento pelo qual o procurador transfere
              os poderes recebidos para outra pessoa, que irá substituí-lo na
              prática dos atos em nome do outorgante originário.
            </p>

            <p>
              O substabelecimento pode ser total ou parcial, com ou sem reserva
              de poderes.
            </p>

            <p>
              O substabelecimento segue a mesma forma exigida para a prática do
              ato. Ou seja, se a lei determinar que o ato é solene e deve ser
              praticado por instrumento público, o substabelecimento da
              procuração pública também deverá ser feito sob a forma pública.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="http://2tabeliaobotucatu.com.br/notas/procuracao-e-substabelecimento/"
                target="_blank"
              >
                <small>
                  {" "}
                  http://2tabeliaobotucatu.com.br/notas/procuracao-e-substabelecimento/
                </small>
              </Link>
            </p>
            <hr />
            <h5>
              Quais são os documentos necessários para efetuar um
              substabelecimento de procuração?
            </h5>
            <p>
              O interessado em substabelecer procuração que lhe foi outorgada
              deverá apresentar
            </p>
            <ul className="fs-6-9">
              <li>
                a via original da procuração originária, na qual não deverá
                constar o veto ao substabelecimento;
              </li>
              <li>
                documentos pessoais utilizados na lavratura de procuração.
              </li>
            </ul>
            <hr />
            <h5>
              É possível fazer o substabelecimento de uma procuração pública por
              procuração particular?
            </h5>
            <p>
              Conforme o art. 655 do Código Civil, ainda quando se outorgue
              mandato por instrumento público, pode substabelecer-se mediante
              instrumento particular. Assim, o interessado poderá optar por
              fazer substabelecimento por instrumento particular e efetuar o
              procedimento de acordo com os itens anteriores.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="http://cartoriofelix.com.br/servicos/detalhe/servico/41-substabelecimento"
                target="_blank"
              >
                <small>
                  {" "}
                  http://cartoriofelix.com.br/servicos/detalhe/servico/41-substabelecimento
                </small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
