import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import slide1 from "../imgs/slide/1.jpg";
import slideMobile from "../imgs/slide-mobile.jpg";

export const CarouselComp = () => {
  const [screenwidth, setScreenWidth] = useState(window.innerWidth);

  console.log(screenwidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="carousel-wrapper">
      {screenwidth >= 768 ? (
        <Carousel
          showThumbs={false}
          showArrows={true}
          autoPlay={true}
          interval={13000}
          infiniteLoop={true}
          transitionTime={1500}
          showStatus={false}
          stopOnHover={false}
          className="carousel"
        >
          <div>
            <img src={slide1} alt="Imagem do Slide" />
          </div>
          <div>
            <img src={slide1} alt="Imagem do Slide" />
          </div>
          <div>
            <img src={slide1} alt="Imagem do Slide" />
          </div>
          <div>
            <img src={slide1} alt="Imagem do Slide" />
          </div>
          <div>
            <img src={slide1} alt="Imagem do Slide" />
          </div>
        </Carousel>
      ) : (
        <Carousel
          showThumbs={false}
          showArrows={true}
          autoPlay={true}
          interval={13000}
          infiniteLoop={true}
          transitionTime={1500}
          showStatus={false}
          stopOnHover={false}
          className="carousel"
        >
          <div>
            <img src={slideMobile} alt="Imagem do slide mobile" />
          </div>
        </Carousel>
      )}
    </div>
  );
};
