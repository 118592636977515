import React from "react";

export const Prazos = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>Prazos dos serviços</h3>
        <span className=""></span>
      </div>
      <div className="container quem-somos d-flex tabela-emolumentos">
        <h4>Tabelionato de Notas</h4>
        <div className="d-flex flex-column w-75">
          <p>
            <strong>E-notariado</strong> (Imediato, desde que o usúario já
            possua o certificado e-notariado ou ICP Brasil)
          </p>
          <p>
            <strong>Autenticação</strong> (Imediato)
          </p>
          <p>
            <strong>Reconhecimento de Firmas</strong> (Imediato)
          </p>
          <p>
            <strong>Apostilamento de Haia</strong> (Imediato)
          </p>
          <p>
            <strong>Procuração</strong> (Imediato)
          </p>
          <p>
            <strong> Revogação de Procuração</strong> (Imediato, desde que
            confirmado junto ao Tabelionato o ato a ser revogado)
          </p>
          <p>
            <strong>Substabelecimento</strong> (Imediato, desde que confirmado
            junto ao Tabelionato o ato a ser revogado)
          </p>
          <p>
            <strong>Ata Notarial</strong> (Em alguns casos, será necessário
            realizar agendamento)
          </p>
          <p>
            <strong>Declaração de união estável</strong> (Imediato ou por
            agendamento, desde que a documentação esteja completa)
          </p>
          <p>
            <strong>Divórcio sem bens</strong> (Imediato)
          </p>
          <p>
            <strong>Divórcio com bens</strong> (Até 5 dias para análise de
            documentos e concretização das assinaturas)
          </p>
          <p>
            <strong>Escritura</strong> (Imediato para escrituras sem valor
            econômico. E com valor economico até 5 dias para análise de
            documentos e concretização das assinaturas)
          </p>
          <p>
            <strong>Escritura pública de doação</strong> (Até 5 dias para
            análise de documentos e concretização das assinaturas)
          </p>
          <p>
            <strong>Inventário</strong> (Até 5 dias para análise de documentos e
            posteriormente será agendado)
          </p>
        </div>
           <h4>Registro Civil</h4>
        <div className="d-flex flex-column w-75">
          <p>
            <strong>Averbações</strong> (Imediato)
          </p>
          <p>
            <strong>Casamento</strong> (A habilitação é imediata, a cerimônia de
            casamento poderá ocorrer em 20 dias após a abertura do processo de
            habilitação)
          </p>
          <p>
            <strong>Nascimento</strong> (Imediato)
          </p>
          <p>
            <strong>Óbito</strong> (Imediato)
          </p>
          <p>
            <strong>Reconhecimento de Paternidade</strong> (Imediato)
          </p>
          <p>
            <strong>Retificação de Registro Civil</strong> (Imediato)
          </p>
          <p>
            <strong>Edital de Proclamas Imediato</strong> (Imediato)
          </p>
        </div>
      </div>
    </div>
  );
};
