import React from "react";

export const RevogacaoProcuracao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>REVOGAÇÃO DE PROCURAÇÃO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é? </h5>
            <p>
              A revogação é o ato que torna sem efeito uma procuração
              anteriormente feita.
            </p>
            <p>
              Por ser um ato baseado na confiança que o outorgante possui no
              procurador, a procuração pode ser revogada a qualquer tempo, se
              não mais convier ao outorgante que o procurador continue exercendo
              atos em seu nome.
            </p>
            <hr />
            <h5>Como é feito? </h5>
            <p>A procuração pode ser revogada a qualquer tempo.</p>
            <p>
              Se a relação de confiança entre as partes deixou de existir, o
              interessado deve providenciar imediatamente a revogação da
              procuração ou a renúncia dos poderes para que a mesma deixe de
              produzir efeitos.
            </p>
            <p>
              Não basta simplesmente rasgar o documento. Enquanto não for
              cancelada oficialmente, a procuração pública continua válida,
              salvo se houver prazo determinado para sua validade ou se a mesma
              houver sido conferida para a conclusão de um negócio específico.
            </p>
            <p>
              Como regra, a procuração perde seus efeitos com a morte ou
              interdição de uma das partes.
            </p>
            <p>
              O interessado (outorgante) comparece ao Tabelionato, com seu RG e
              CPF originais, e diz ao escrevente que nomeou alguém de sua
              confiança (procurador), mas que não deseja mais que esta
              procuração tenha validade, e por isso, deseja fazer sua revogação.
            </p>
            <hr />
            <h5>O que é necessário?</h5>
            <ul className="fs-6-9">
              <li>
                Comparecer o interessado com seus documentos originais (RG ou
                Carteira de Habilitação nova (com foto), e CPF);
              </li>
              <li>Trazer uma cópia da procuração que vai ser revogada.</li>
            </ul>
            <p>
              <b>
                Atenção: A procuração pública pode ser revogada em qualquer
                cartório de notas, independentemente de onde ela tenha sido
                feita.
              </b>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
