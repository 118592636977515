import React from "react";
import { Link } from "react-router-dom";

export const Divorcio = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>DIVÓRCIO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              O divórcio existe quando o compromisso civil do casal é encerrado,
              o que põe fim à sociedade e ao vínculo conjugal. A pessoa que se
              divorcia deixa de ter obrigações com o cônjuge.
            </p>
            <p>
              No divórcio o casamento realmente acaba, e assim é possível que a
              pessoa se case-se outra vez. Mas o casal divorciado não poderá
              reatar a não ser que se case de novo. É possível se divorciar
              mesmo ainda morando junto com a outra pessoa, mas são exigidas
              provas de que não há mais relação de casamento.
            </p>
            <h6>Divórcio em cartório (extrajudicial)</h6>
            <p>
              Para o divórcio ser feito extrajudicialmente, ou seja, diretamente
              no cartório e não através da justiça, é preciso:
            </p>
            <ul className="fs-6-9">
              <li>ser de comum acordo (amigável)</li>
              <li>
                que o casal não tenha filhos menores de idade ou incapazes. Se o
                casal tiver filhos, eles precisam ser maiores de idade.
              </li>
            </ul>
            <p>
              Se o divórcio em questão atender a esses dois pré-requisitos,
              poderá então ser feito diretamente no cartório, o que torna o
              processo de separação muito mais rápido e menos burocrático. Neste
              caso, é necessária a presença de um advogado junto com o casal no
              cartório, podendo ser o mesmo advogado para os dois.
            </p>
            <p>
              O divórcio consensual (amigável) tornou-se muito simples através
              da Lei 11.441/07, que permitiu a realização da separação em
              cartório (somente Tabelionato de Notas) e não mais através da
              justiça.
            </p>

            <h6>Pré-requisitos </h6>
            <p>Para que o divórcio possa ser feito no cartório, é preciso:</p>

            <ul className="fs-6-9">
              <li>ser de comum acordo (amigável);</li>
              <li>
                que o casal não tenha filhos menores de idade ou incapazes. Se o
                casal tiver filhos, eles precisam ser maiores de idade.
              </li>
            </ul>
            <h6>Documentos necessários</h6>

            <ul className="fs-6-9">
              <li>
                Cônjuges: RG, CPF (originais) e certidão de casamento (original)
                atualizada com expedição de no máximo 90 dias, com firma
                reconhecida e se tiverem filhos maiores de idade, trazer com os
                documentos de identidade (originais);
              </li>
              <li>
                Advogado: cópia simples da OAB e CPF do assistente/advogado,
                mais estado civil e endereço;{" "}
              </li>
            </ul>
            <p>
              No dia da assinatura do divórcio, todos devem comparecer
              pessoalmente com os documentos de identidade (originais) e os
              documentos solicitados.
            </p>
            <p>
              Depois de feito o divórcio no Tabelionato de Notas é necessário
              enviar a escritura para o Cartório de Registro Civil em que foi
              realizado o casamento para seja feita a averbação de divórcio na
              certidão de casamento.
            </p>
            <p>
              Somente a partir desse momento é que o divórcio passa a ter
              efeito.
            </p>
            <hr />
            <h5>Divisão de bens </h5>
            <p>
              Se houverem bens ou dívidas a serem divididos ou penåsão
              alimentícia, deverá constar na escritura do divórcio os termos
              acordados. A divisão de bens/dívidas será então, naturalmente,
              realizada de acordo com o regime de bens do casamento.
            </p>
            <hr />
            <h5>Mudança de nome </h5>
            <p>
              Também deverá ser definido na escritura pública do divórcio se o
              cônjuge após o divórcio passará a usar o nome de solteiro,
              anterior ao casamento, ou se permanecerá com o nome de casado,
              ambas as opções são possíveis, cabendo a cada cônjuge escolher o
              que prefere.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="https://www.casamentocivil.com.br/divorcio"
                target="_blank"
              >
                https://www.casamentocivil.com.br/divorcio
              </Link>
              <Link
                to="https://www.casamentocivil.com.br/divorcio/divorcio-em-cartorio"
                target="_blank"
              >
                https://www.casamentocivil.com.br/divorcio/divorcio-em-cartorio
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
