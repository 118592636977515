import React from "react";

export const Autenticacao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>AUTENTICAÇÃO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              As cópias autenticadas por Tabelião, em meio digital ou em papel,
              têm o mesmo valor de prova que os originais, e fazem prova plena
              para todos os efeitos legais. A autenticação é feita após a
              conferência da cópia com o documento original, existente no
              Tabelionato ou exibida pelo apresentante.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
