import React from "react";

import Requerimentos from "../components/Requerimentos";

export const ModeloRequerimentos = () => {
  const storage = [
    "/storage/requerimento_de_retificacao_de_erro_de_grafia.pdf",
    "/storage/requerimento_solicitacao_certidao_inteiro_teor.pdf",
    "/storage/requerimento_registro_nascimento_tardio.pdf",
    "/storage/requerimento_termo_de_opcao_de_regime_casamento_parcial_de_bens.pdf",
    "/storage/requerimento_autorizacao_de_viagem_nacional.pdf",
    "/storage/requerimento_autorizacao_de_viagem_internacional.pdf",
    "/storage/requerimento_autorizacao_de_viagem_internacional_desacompahado.pdf",
    "/storage/requerimento_autorizacao_para_festa.pdf",
    "/storage/RPJ_declaracao_enquadramento_microempresa.docx",
  ];

  const hostLinks = "https://iuryflores.com.br/3rcgo";

  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>Requerimentos</h3>
        <span className=""></span>
      </div>
      <section className="conteudo-pagina utilidades">
        <div className="servicosContainer holder flexbox">
          <Requerimentos
            title="Retificação de erro de grafia"
            category="Registro Civil"
            desc="O erro de grafia refere-se àquele, constante do assento, que foi
                evidentemente cometido pelo encarregado quando da sua
                realização. Desta forma, deve ser interpretado como tal erro
                constante do assento que, a uma simples verificação e a toda
                evidência, se constata que foi cometido por quem o lavrou."
            link={`${hostLinks}${storage[0]}`}
          />
          <Requerimentos
            title="Solicitação de certidão inteiro teor"
            category="Registro Civil"
            desc="Certidão em inteiro teor ou integral é um documento extraído de
                um livro de registro que reproduz todas as palavras nele
                contidas."
            link={`${hostLinks}${storage[1]}`}
          />
          <Requerimentos
            title="Registro tardio de nascimento"
            category="Registro Civil"
            desc="A ação judicial de registro tardio, nada mais é do que um pedido
                ao juiz para que ele mande o cartório registar o evento
                (nascimento ou casamento) tardiamente, ou seja, anos após o
                nascimento."
            link={`${hostLinks}${storage[2]}`}
          />
          <Requerimentos
            title="Termo de opção de regime casamento – Parcial de Bens"
            category="Registro Civil"
            desc="Com este termo, os cônjuges declaram estar cientes de que no
                regime da comunhão parcial comunicam-se os bens adquiridos pelo
                casal na constância do casamento e que ficam excluídos os bens
                havidos anteriormente."
            link={`${hostLinks}${storage[3]}`}
          />
          <Requerimentos
            title="Autorização de viagem nacional"
            category="Tabelionato de notas"
            desc="A Autorização será necessária apenas quando o menor de 12 anos
                viajar totalmente desacompanhado dos pais, parentes ou de
                terceiros e comparecer pessoalmente à Vara da Infância e da
                Juventude da Comarca onde reside, munido dos documentos
                necessários."
            link={`${hostLinks}${storage[4]}`}
          />
          <Requerimentos
            title="Autorização de viagem internacional"
            category="Tabelionato de notas"
            desc="A autorização é exigida sempre que crianças e adolescentes
                brasileiros precisarem viajar para outros países
                desacompanhados, na companhia de apenas um dos pais ou
                acompanhados de terceiros."
            link={`${hostLinks}${storage[5]}`}
          />
          <Requerimentos
            title="Autorização de viagem internacional desacompanhado"
            category="Tabelionato de notas"
            desc="A autorização é exigida sempre que crianças e adolescentes
                brasileiros precisarem viajar para outros países
                desacompanhados, na companhia de apenas um dos pais ou
                acompanhados de terceiros."
            link={`${hostLinks}${storage[6]}`}
          />
          <Requerimentos
            title="Autorização para festa"
            category="Tabelionato de notas"
            desc="A autorização dos pais ou responsáveis para criança ou
                adolescente é o documento por meio do qual a mãe, o pai ou o
                responsável legal por uma criança ou adolescente lhe autoriza a
                realizar determinada atividade, desacompanhado."
            link={`${hostLinks}${storage[7]}`}
          />
          <Requerimentos
            title="Declaração de enquadramento de microempresa"
            category="Registro de Pessoas Jurídicas"
            desc="Esta declaração é um termo assinado pelo empresário e que deve
                estar registrada na Junta Comercial para que a empresa faça jus
                a uma série de benefícios concedidos pelo Estatuto da Micro e
                Pequena Empresa."
            link={`${hostLinks}${storage[8]}`}
          />
        </div>
      </section>
    </div>
  );
};
