import React from "react";

export const EditalProclamas = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>EDITAL DE PROCLAMAS</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              Certidão de Nascimento, além de ser um documento de identificação,
              é a primeira garantia de cidadania e direitos a todos os
              brasileiros. Com a Certidão de Nascimento, a criança terá direito
              de ser atendida em todos os serviços públicos como, por exemplo,
              hospitais, postos de saúde, escolas, etc. Para que esses direitos
              possam ser exigidos desde os primeiros dias de vida, todas as
              crianças devem ser registradas logo após seu nascimento.
            </p>
            <hr />
            <h5>Qual o prazo para registrar a criança?</h5>
            <ul className="fs-6-9">
              <li>A criança deverá ser registrada em até 15 dias.</li>
              <li>
                Caso seja a mãe a única pessoa disponível para registrar a
                criança, o prazo será aumentado para 45 dias.
              </li>
              <li>
                Caso a distância do local de nascimento e do cartório mais
                próximo seja superior a 30 quilômetros, o prazo para o registro
                será de 90 dias.
              </li>
            </ul>
            <hr />
            <h5>Quem deve requerer o Registro de Nascimento?</h5>
            <p>
              Segundo o artigo 52 da Lei dos Registros Públicos (Lei 6015/1973)
              com redação alterada pela Lei 13,112/2015, são obrigados a fazer a
              declaração de nascimento, na seguinte ordem:
            </p>
            <ul className="fs-6-9">
              <li>
                o pai ou a mãe, isoladamente ou em conjunto, observado o
                disposto no § 2º do art. 54; (Redação dada pela Lei nº 13.112,
                de 2015)
              </li>
              <li>
                no caso de falta ou de impedimento de um dos indicados no item
                1º, outro indicado, que terá o prazo para declaração prorrogado
                por 45 (quarenta e cinco) dias; (Redação dada pela Lei nº
                13.112, de 2015)
              </li>
              <li>
                no impedimento de ambos, o parente mais próximo, sendo maior
                achando-se presente;
              </li>
              <li>
                em falta ou impedimento do parente referido no número anterior
                os administradores de hospitais ou os médicos e parteiras, que
                tiverem assistido o parto;
              </li>
              <li>
                pessoa idônea da casa em que ocorrer, sendo fora da residência
                da mãe;
              </li>
              <li>
                finalmente, as pessoas (VETADO) encarregadas da guarda do menor.
                (Redação dada pela Lei nº 6.216, de 1975).
              </li>
            </ul>
            <hr />
            <h5>Qual o custo?</h5>
            <p>É gratuita a emissão da 1ª via da certidão de nascimento.</p>
            <p>
              Porém, é cobrada uma taxa para a emissão da 2ª via, a qual varia
              em cada Estado.
            </p>
            <hr />
            <h5>
              Documentos necessários para tirar a certidão de nascimento
              [registrar a criança]
            </h5>
            <p></p>
            <h6>Pais casados:</h6>
            <p>
              Só é necessária a presença de um dos pais no cartório, devendo
              levar os seguintes documentos:
            </p>
            <ul className="fs-6-9">
              <li>Carteira de identidade (RG ou Carteira de Habilitação); </li>
              <li>
                Declaração de Nascido Vivo (fornecido pelo hospital onde a
                criança nasceu);
              </li>
              <li>Certidão de Casamento;</li>
            </ul>
            <p></p>
            <h6>Pais não casados:</h6>
            <p>
              Podem comparecer juntos ao cartório, ou somente o pai (aplica-se o
              “atenção” acima neste caso, também), devendo levar os seguintes
              documentos:
            </p>
            <ul className="fs-6-9">
              <li>Documento de identidade (RG ou Carteira de Habilitação); </li>
              <li>
                Declaração de Nascido Vivo (fornecido pelo hospital onde a
                criança nasceu);
              </li>
            </ul>
            <p></p>
            <h6>Pais menores de 16 anos:</h6>
            <p>
              Os pais menores que 16 anos, para registrar seus filhos, devem se
              dirigir ao cartório acompanhados dos avós da criança ou de seu
              responsável legal, todos munidos de documento de identidade (RG ou
              Carteira de Habilitação) e a Declaração de Nascido Vivo.
            </p>
            <p></p>
            <h6>Pai desconhecido:</h6>
            <p>
              Nesse caso, a criança será registrada e na filiação constará
              apenas o nome da mãe. A mãe deverá comparecer ao cartório com os
              seguintes documentos:
            </p>
            <ul className="fs-6-9">
              <li>Documento de identidade (RG ou Carteira de Habilitação); </li>
              <li>
                Declaração de Nascido Vivo (fornecido pelo hospital onde a
                criança nasceu);
              </li>
            </ul>
            <h6>
              Crianças que nasceram em casa ou em caso de perda Declaração de
              Nascido Vivo:
            </h6>
            <p>
              Além de um dos pais, também deverão comparecer ao cartório 2
              testemunhas que comprovem a gravidez da mãe, todos portando
              documento de identidade (RG ou Carteira de Habilitação).
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
