import React from "react";
import { Link } from "react-router-dom";

export const AtaNotarial = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>ATA NOTARIAL</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              A ata notarial é o instrumento celebrado e formalizado pelo
              Tabelião Público que tem por finalidade constatar e certificar a
              existência efetiva ou concreta de um fato jurídico. Fato jurídico
              é todo fato natural ou proveniente de ato humano, voluntário ou
              involuntário, que venha a provocar mudança em determinada situação
              concreta de efeitos jurídicos.
            </p>

            <p>
              Desse modo, sempre que uma conduta ou comportamento humano, ou
              quando um fato natural possa provocar modificação ou interferência
              no exercício de um direito por parte de uma pessoa, a exemplo de
              uma vistoria ou verificação física, essa situação concreta pode
              ser certificada através de uma ata notarial. Pela ata notarial, o
              Tabelião ou um preposto credenciado do Tabelionato, comparecendo
              ao local designado pela parte interessada, irá lavrar uma ata de
              certificação ou constatação que vai conter a narrativa, no local,
              da ocorrência ou da existência de um fato relevante.
            </p>
            <p>
              E essa ata notarial, lavrada em livro próprio, constitui prova
              documental plena para evidenciar o fato ou circunstância concreta
              efetivamente ocorrida. A ata notarial, como elemento de prova,
              produz efeitos tanto no âmbito de negócios e relações privadas,
              como em processos judiciais.
            </p>
            <p>
              <small>Fonte consultada: Cartório Nocko</small>
            </p>

            <p>São exemplos de atas notariais</p>
            <ul className="fs-6-9">
              <li>
                Mensagens de celular cujo conteúdo se queira preservar (SMS,
                Whatsapp, Skype);
              </li>
              <li>
                Conteúdo de páginas na internet e mídias sociais (YouTube);
              </li>
              <li>
                Recados e postagens em redes sociais (Facebook, Twitter,
                Snapchat);
              </li>
              <li>Conteúdo de e-mails e identificação do IP emissor;</li>
              <li>Abertura de cofres;</li>
              <li>Entrega de chaves;</li>
              <li>Descrição de sinistros (acidentes, incêndios);</li>
              <li>Reuniões de condomínio;</li>
              <li>Assembleias societárias;</li>
              <li>Verificação do estado de coisas móveis ou imóveis.</li>
            </ul>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="https://4tabelionatodenotas.com.br/ata-notarial/"
                target="_blank"
              >
                <small>https://4tabelionatodenotas.com.br/ata-notarial/</small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
