import React from "react";
import GalleryCarousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

import image1 from "../imgs/galeria/DSCF64192.jpg";
import image2 from "../imgs/galeria/IMG_14772.jpg";
import image3 from "../imgs/galeria/IMG_15092.jpg";
import image4 from "../imgs/galeria/IMG_15142.jpg";
import image5 from "../imgs/galeria/IMG_15202.jpg";
import image6 from "../imgs/galeria/IMG_15412.jpg";
import image7 from "../imgs/galeria/IMG_15612.jpg";
import image8 from "../imgs/galeria/IMG_4546.jpg";

const photos = [
  { src: image6, alt: "Foto 2" },
  { src: image7, alt: "Foto 1" },
  { src: image2, alt: "Foto 2" },
  { src: image3, alt: "Foto 1" },
  { src: image4, alt: "Foto 2" },
  { src: image5, alt: "Foto 1" },
  { src: image8, alt: "Foto 2" },
  { src: image1, alt: "Foto 1" }
];

export const Galery = () => {
  return (
    <div className="gallery-small w-50">
      <h2>Galeria de fotos</h2>
      <GalleryCarousel
        images={photos}
        canAutoPlay={false}
        isAutoPlaying={false}
      />
    </div>
  );
};
