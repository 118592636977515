import React from "react";
import { Link } from "react-router-dom";

export const DeclaracaoUniaoEstavel = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>DECLARAÇÃO DE UNIÃO ESTÁVEL</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              A Declaração de União Estável, também chamada de Certidão de União
              Estável, é um documento público declaratório firmado pelos
              conviventes no cartório de notas, que oficializa a união estável e
              também define diversas regras aplicáveis à referida relação como:
              regime de bens, cláusulas, pagamento de pensão, titularidade de
              bens, etc.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="https://guiadocumentos.com.br/declaracao-de-uniao-estavel/"
                target="_blank"
              >
                {" "}
                <small>
                  https://guiadocumentos.com.br/declaracao-de-uniao-estavel/
                </small>
              </Link>
            </p>
            <hr />
            <h5>
              Como fazer a Declaração de União Estável e quais os documentos
              necessários
            </h5>
            <p>A união estável poderá ser formalizada por duas maneiras:</p>
            <ul className="fs-6-9">
              <li>
                Através de escritura pública de declaração de união estável
                firmada no Cartório de Notas, ou;
              </li>
              <li>
                Por meio de contrato particular, o qual pode ser levado a
                registro no Cartório de Registro de Títulos e Documentos.
              </li>
            </ul>

            <h6>
              Vamos agora detalhar as duas maneiras de oficialização da união
              estável:
            </h6>

            <hr />
            <h5>1 - Por meio de escritura pública</h5>
            <p>
              A união estável oficializada por meio de escritura pública é
              lavrada por notário oficial e tem a finalidade de dar publicidade
              dos termos nela contidos perante terceiros (é o chamado efeito
              erga omnes).
            </p>
            <p>
              Tem o objetivo de não deixar nenhuma dúvida quanto a sua
              existência em sede de eventual questionamento sobre a existência
              da união.
            </p>
            <p>
              Além da publicidade automática, a escritura pública de declaração
              de união estável ficará arquivada no tabelionato.
            </p>
            <p></p>
            <h6>Onde fazer?</h6>
            <p>
              O procedimento todo é feito no Cartório de Notas, bastando os
              declarantes se apresentarem perante o tabelião.
            </p>
            <p>
              Ato contínuo, deverão deliberar sobre o regime de bens e demais
              declarações de acordo com a vontade das partes.
            </p>
            <p>
              <b>Não é necessário presença de testemunhas.</b>
            </p>
            <h6>Requisitos:</h6>
            <ul className="fs-6-9">
              <li>Inexistência de impedimentos matrimoniais</li>
            </ul>
            <h6>Documentos necessários:</h6>
            <p>Os conviventes deverão apresentar:</p>
            <ul className="fs-6-9">
              <li>Documento de identidade original;</li>
              <li>CPF;</li>
              <li>Comprovante de endereço;</li>
              <li>
                Certidão de nascimento ou casamento emitida em até 90 dias.
              </li>
            </ul>

            <h6>
              Quanto custa lavrar a escritura pública de declaração de união
              estável?
            </h6>
            <p>
              O valor cobrado para lavrar a Declaração de União Estável, bem
              como, para proceder o registro do Contrato Particular de União
              Estável pelo Cartório de Registro de Documentos constam na tabela
              de custas dos serviços.
            </p>
            <h6>
              Preciso estar presente para lavrar a escritura pública de
              Declaração de União Estável?
            </h6>
            <p>
              Não necessariamente. É possível a nomeação de procurador para
              representar uma ou ambas as partes para a realização do ato.
            </p>

            <h6>O que é a Certidão de União Estável?</h6>
            <p>
              A certidão de união estável é o documento emitido pelo Cartório de
              Notas que certifica e dá fé pública à Declaração de União Estável
              lavrada.
            </p>
            <hr />
            <h5>Por meio de contrato particular</h5>
            <p>
              Os conviventes também podem oficializar a união estável por meio
              de um contrato particular.
            </p>
            <p>
              O casal pode estipular a data de início da convivência, regime de
              bens, regras aplicáveis em caso de dissolução da união estável,
              enfim, é possível adicionar cláusulas de acordo com a vontade dos
              contratantes.
            </p>
            <p>
              É extremamente aconselhável que o contrato seja feito sob vista de
              um advogado.
            </p>
            <h6>Requisitos:</h6>
            <ul className="fs-6-9">
              <li>Inexistência de impedimentos matrimoniais;</li>
              <li>
                Assinatura com firma reconhecida de pelo menos 2 testemunhas
                maiores e capazes.
              </li>
            </ul>

            <h6>Registro do contrato de união estável em cartório</h6>
            <p>
              Os conviventes poderão, após a celebração do contrato, levá-lo a
              registro no Cartório de Registro de Títulos e Documentos para
              gerar publicidade perante terceiros.
            </p>
            <p>
              Por ser o contrato um instrumento particular, somente gera efeitos
              entre os contratantes, até ser levado à registro.
            </p>
            <p>
              Após registrado, assim como ocorre com a escritura pública, nenhum
              terceiro poderá alegar desconhecimento da relação jurídica de
              união estável.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
