import React from "react";
import { Link } from "react-router-dom";

export const MenuMobile = ({ collapseMenu }) => {
  return (
    <ul className="navbar-nav nav-mobile mr-auto container d-flex justify-content-around align-items-start">
      <Link className="nav-link" to="/" onClick={collapseMenu}>
        INÍCIO
      </Link>

      <Link className="nav-link" to="/quem-somos/" onClick={collapseMenu}>
        QUEM SOMOS
      </Link>
      {/*
      <Link className="nav-link" to="/noticias/" onClick={collapseMenu}>
        NOTÍCIAS
      </Link>
  */}
      <Link className="nav-link">REGISTRO CIVIL</Link>
      <div className="dropdown-menu-mobile">
        <Link
          className="dropdown-item"
          to="/servicos/certidoes/"
          onClick={collapseMenu}
        >
          Certidões
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/nascimento/"
          onClick={collapseMenu}
        >
          Nascimento
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/retificacao-de-registro-civil/"
          onClick={collapseMenu}
        >
          Retificação de Registro Civil
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/casamento/"
          onClick={collapseMenu}
        >
          Casamento
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/obito/"
          onClick={collapseMenu}
        >
          Óbito
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/reconhecimento-de-paternidade/"
          onClick={collapseMenu}
        >
          Reconhecimento de Paternidade
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/edital-de-proclamas/"
          onClick={collapseMenu}
        >
          Edital de Proclamas
        </Link>
      </div>

      <Link className="nav-link">TABELIONATO DE NOTAS</Link>
      <div className="dropdown-menu-mobile">
        <Link
          className="dropdown-item"
          to="/servicos/e-notariado/"
          onClick={collapseMenu}
        >
          E-Notariado
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/escritura-publica/"
          onClick={collapseMenu}
        >
          Escritura pública
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/escritura-publica-de-doacao/"
          onClick={collapseMenu}
        >
          Escritura pública de doação
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/procuracao/"
          onClick={collapseMenu}
        >
          Procuração
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/apostilamento-haia/"
          onClick={collapseMenu}
        >
          Apostilamento de Haia
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/declaracao-de-uniao-estavel/"
          onClick={collapseMenu}
        >
          Declaração de união estável
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/ata-notarial/"
          onClick={collapseMenu}
        >
          Ata Notarial
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/ata-notarial-usucapiao/"
          onClick={collapseMenu}
        >
          Ata Notarial de Usucapião
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/autenticacao/"
          onClick={collapseMenu}
        >
          Autenticação
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/reconhecimento-de-firma/"
          onClick={collapseMenu}
        >
          Reconhecimento de Firma
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/divorcio/"
          onClick={collapseMenu}
        >
          Divórcio
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/revogacao-de-procuracao/"
          onClick={collapseMenu}
        >
          Revogação de Procuração
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/inventario/"
          onClick={collapseMenu}
        >
          Inventário
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/testamento/"
          onClick={collapseMenu}
        >
          Testamento
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/malote-virtual/"
          onClick={collapseMenu}
        >
          Malote Virtual
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/emancipacao/"
          onClick={collapseMenu}
        >
          Emancipação
        </Link>
        <Link
          className="dropdown-item"
          to="/servicos/substabelecimento/"
          onClick={collapseMenu}
        >
          Substabelecimento
        </Link>
      </div>
      <Link className="nav-link " to="/prazos/" onClick={collapseMenu}>
        PRAZOS DOS SERVIÇOS
      </Link>
      <Link
        className="nav-link "
        to="/modelos-requerimentos/"
        onClick={collapseMenu}
      >
        MODELOS DE REQUERIMENTOS
      </Link>
      <Link
        className="nav-link"
        to="/custas-e-emolumentos/"
        onClick={collapseMenu}
      >
        Custas e Emolumentos
      </Link>

      <Link className="nav-link ">LGPD</Link>
      <div className="dropdown-menu-mobile">
        <Link
          className="dropdown-item"
          to="https://iuryflores.com.br/3rcgo/AvisoGeraldePrivacidadeeCookies.pdf"
          target="_blank"
          onClick={collapseMenu}
        >
          Aviso de Privacidade
        </Link>
        <Link
          className="dropdown-item"
          to="https://iuryflores.com.br/3rcgo/3. Termo de Consentimento e Aviso de Privacidade para Candidatos.pdf"
          target="_blank"
          onClick={collapseMenu}
        >
          Aviso de Privacidade para Candidatos
        </Link>
        <Link
          className="dropdown-item"
          to="/solicitacao-dados/"
          onClick={collapseMenu}
        >
          Solicitação de Dados
        </Link>
      </div>
      <Link className="nav-link" to="/fale-conosco/" onClick={collapseMenu}>
        FALE CONOSCO
      </Link>
    </ul>
  );
};
