import React from "react";
import { Link } from "react-router-dom";

export const Certidoes = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>CERTIDÕES</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div className="content-internas col-md-12 col-lg-12 col-sm-12">
            <div className="texto-servico">
              <p>
                Realizamos buscas rápidas e imediatas, entregando na hora as
                segundas vias de registro.
              </p>

              <p>
                <strong>Informações necessárias&nbsp;</strong>
                <strong>para&nbsp;solicitar&nbsp;</strong>(verbalmente ou por
                escrito) certidão/segunda via:
              </p>
              <p>– Nome completo</p>
              <p>– Filiação</p>
              <p>– Data de nascimento (registro de nascimento)</p>
              <p>– Data de casamento (registro de casamento)</p>
              <p>– Data de óbito (registro de óbito)</p>
              <p>&nbsp;</p>
              <p>
                <strong>Tipos de</strong>&nbsp;<strong>certidões</strong>
                &nbsp;mais solicitados:
              </p>
              <p>
                –&nbsp;<strong>Segunda via</strong>&nbsp;
                <strong>de registro</strong>
              </p>
              <p>
                É uma certidão fornecida com as principais informações do
                registro (em breve relato –&gt; resumidamente).
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>– Segunda via em&nbsp;Inteiro Teor</strong>
              </p>
              <p>
                É uma certidão com todas&nbsp;as informações do registro (
                <u>sem</u>
                &nbsp;resumo) e geralmente é exigida por alguns órgãos do
                exterior, podendo ser fornecida digitada ou por meio de imagem
                (cópia) do termo (folha do livro).
              </p>
              <p>
                Exige-se autorização judicial quando o registro possui
                averbações que a lei restringe a publicidade (adoção,
                legitimação por matrimônio, etc…)
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>
                  – Certidão&nbsp;Negativa&nbsp;de nascimento, casamento ou
                  óbito
                </strong>
              </p>
              <p>
                É uma certidão que informa sobre a&nbsp;
                <strong>
                  <u>INE</u>
                </strong>
                <strong>
                  <u>XISTÊNCIA</u>
                </strong>{" "}
                de registros na serventia e muito usada para comprovar estado
                civil no exterior ou solicitar Certificado ou Atestado de
                Celibato (Declaração de Solteiro ou Capacidade Matrimonial).
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>Formas de fornecimentos das certidões</strong>:
              </p>
              <p>
                Qualquer pessoa poderá solicitar e receber a certidão pelos
                seguintes meios:
              </p>

              <p>
                <strong>Pessoalmente</strong>&nbsp;– Comparecer diretamente no
                cartório com as informações necessárias e a emissão e entrega da
                certidão são realizadas na hora,&nbsp;inclusive&nbsp;a segunda
                via em Inteiro Teor, a não ser que se trata de caso que exija
                autorização judicial.
              </p>

              <p>
                <strong>Online - Presencialmente no Cartório</strong>&nbsp;–
                Você pode se dirigir a qualquer cartório do Brasil para
                solicitar a certidão, que será processada e recebida online
                através do CRC (Central de Registro Civil).
              </p>
              <p>
                <strong>Online - Via Internet</strong> – Solicite certidões de
                forma rápida e conveniente pelo site do Registro Civil. Você tem
                a opção de escolher entre a certidão eletrônica ou a certidão
                física (impressa em papel oficial), que poderá ser enviada para
                o seu endereço ou retirada em um Cartório de sua preferência.
                Acesse o site para realizar a solicitação:{" "}
                <Link
                  to="https://registrocivil.org.br/"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    fontSize: "0.9rem !important",
                    textDecoration: "underline",
                  }}
                >
                  https://registrocivil.org.br/
                </Link>
                .
              </p>
              <p>
                <strong>Correios</strong>&nbsp;– realizar a solicitação da
                certidão pelo site do cartório e seu envio será pelos Correios,
                após confirmação do depósito/transferência bancária das
                despesas.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
