import React from "react";
import { Link } from "react-router-dom";

export const Procuracao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>PROCURAÇÃO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h2>O que é procuração?</h2>
            <p>
              Procuração é o instrumento pelo qual uma pessoa nomeia outra de
              sua confiança como seu representante (procurador), para agir em
              seu nome em determinada situação em que não possa estar presente.
            </p>
            <p>
              Na representação para a prática de atos complexos e solenes, como
              por exemplo, venda e doação de bens imóveis, representação em
              casamento ou em escrituras de divórcio e inventário, a lei exige
              poderes especiais e procuração na forma pública, feita em
              cartório.
            </p>
            <p>
              A representação para a prática de atos que a lei não exige
              escritura pública pode ser feita por procuração particular, com
              firma reconhecida em cartório.
            </p>
            <h2>Quem deve comparecer?</h2>
            <p>
              Basta a presença da pessoa que vai delegar os poderes, isto é, o
              mandante. Sempre que possível, o procurador ou mandatário, a
              pessoa que recebe os poderes, deve comparecer, pois assim já
              assina a procuração, aceitando-a.
            </p>
            <h2>Procuração em causa própria</h2>
            <p>
              A procuração em causa própria configura um contrato preliminar e
              irrevogável de transmissão de direitos sobre bens móveis ou
              imóveis, que permite ao mandatário transferir o bem para si. Na
              prática, a procuração em causa própria sempre versa sobre direito
              imobiliário, contendo a quitação do preço e a transmissão da posse
              e direitos.
            </p>
            <p>
              Renúncia: O procurador renuncia aos poderes outorgados pelo
              mandante. Enquanto o procurador renunciante não notificar o
              mandante sobre a renúncia, ela não produzirá efeitos perante este.
            </p>
            <p>
              Revogação: O mandante revoga, parcial ou totalmente, os poderes
              outorgados ao procurador. O mandante revocante deve notificar o
              procurador sobre a revogação feita. Enquanto tal não ocorrer,
              reputam-se válidos os atos realizados.
            </p>
            <p>
              Substabelecimento: O procurador cede, parcial ou totalmente, os
              poderes de representação para outra pessoa.
            </p>
            <h2>Documentos necessários</h2>
            <p>Pessoa física</p>
             <ul className="fs-6-9">
              <li>RG e CPF, do(s) outorgante(s), originais;</li>
              <li>Comprovante de endereço do(s) outorgante(s);</li>
              <li>
                RG e CPF do(s) outorgado(s), originais ou cópia autenticada;
              </li>
              <li>
                Certidão de casamento (se casado, separado, divorciado ou
                viúvo). Quando for de fora de Goiás, com firma reconhecida do
                oficial que a expediu.
              </li>
            </ul>
            <p>Pessoa jurídica</p>
             <ul className="fs-6-9">
              <li>Número do CNPJ;</li>
              <li>
                Fotocópia autenticada do contrato social, última alteração e
                alteração em que conste modificação de diretoria, ou
                consolidação do contrato social. Se estatuto social, sua
                fotocópia autenticada e a ata de eleição da diretoria;
              </li>
              <li>
                RG, CPF, profissão, estado civil e residência do diretor(es),
                sócio(s) ou procurador(es) que assinará(ão) a escritura;
              </li>
              <li>
                Certidão da junta comercial de que não há outras alterações.
              </li>
            </ul>
            <p>
              Procuração para imóveis: No caso o Tabelião exigirá certidão de
              inteiro teor da matrícula, expedida há menos de 30 (trinta) dias.
            </p>
            <p>
              <small>Fonte consultada: </small>
              <Link
                to="https://www.26notas.com.br/servicos/procuracoes"
                target="_blank"
              >
                <small>https://www.26notas.com.br/servicos/procuracoes</small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
