import React from "react";
import { Link } from "react-router-dom";

export const Emancipacao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>EMANCIPAÇÃO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              A antecipação da capacidade civil plena, conhecida como
              emancipação de menores, é um mecanismo legal que permite que
              pessoas abaixo dos 18 anos de idade possam praticar todos os atos
              da vida civil pessoalmente, sem assistência. É uma ato que
              extingue o poder familiar e dá plena capacidade ao emancipado. A
              emancipação, basicamente, consiste na autorização prévia dos
              responsáveis legais por um menor de idade para que este tenha
              capacidade plena, e possa responder juridicamente por negociações
              ou outras obrigações da vida civil.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="http://franzoni.adv.br/emancipacao-de-menores/"
                target="_blank"
              >
                <small> http://franzoni.adv.br/emancipacao-de-menores/</small>
              </Link>
            </p>

            <hr />
            <h5>Com que idade meu filho pode fazer a emancipação?</h5>
            <p>
              A emancipação voluntária, judicial ou legal pode ser feita a
              partir dos 16 anos de idade. Em cada caso, porém, devem ser
              observados aspectos específicos. Por isso, destacamos que em caso
              de dúvidas, a orientação de um advogado especializado em direito
              da família é indispensável. O cuidado com todas as etapas do
              processo e o conhecimento de todas as suas implicações pode evitar
              problemas futuros ou dores de cabeça desnecessárias. Busque
              informação embasada e qualificada antes de iniciar a emancipação
              de um filho menor de idade.
            </p>

            <hr />
            <h5>3 (três) tipos de Emancipação:</h5>
            <p>
              <b>Emancipação voluntária</b> acontece quando os pais concedem
              esse direito naturalmente ao filho, sem discordância entre a
              vontade de ambos. Caso todas as partes estejam de acordo, não é
              necessária a interferência judicial, e todo o processo pode ser
              feito por uma escritura pública, firmada em cartório. Após feita a
              escritura, deve ser levada a registro no Registro Civil de Pessoas
              Naturais do domicílio do menor, e deve também ser levada a assento
              no Registro de Nascimento do emancipado.
            </p>

            <p>
              <b>Emancipação judicial</b> é feita quando um dos pais não
              concorda com a cedência desse direito ao filho ou, ainda, quando
              os pais não podem exercer suas funções de responsabilidade legal
              pelo menor. Nesse caso, a situação é levada ao Poder Judiciário
              para que este determine, com base na situação fática, se é o caso
              de emancipação ou não.
            </p>

            <p>Emancipação legal</p>
            <ul className="fs-6-9">
              <li>Casamento;</li>
              <li>Colação de grau em curso de ensino superior;</li>
              <li>
                Pelo estabelecimento civil ou comercial, ou pela existência de
                relação de emprego, desde que, em função deles, o menor com
                dezesseis anos completos tenha economia própria.
              </li>
            </ul>

            <hr />
            <h5>Documentos Necessários</h5>

            <p>Dos pais:</p>
            <ul className="fs-6-9">
              <li>- Carteira de identidade</li>
              <li>- CPF</li>
            </ul>

            <p>Do menor:</p>
            <ul className="fs-6-9">
              <li>- Carteira de identidade</li>
              <li>- CPF (se possuir)</li>
              <li>- Certidão de Nascimento</li>
            </ul>

            <h6>Requisitos</h6>
            <p>
              a) Anuência dos pais – não é um dever dos pais, mas uma vontade.
            </p>
            <p>
              <small>
                Obs 1: No entanto, poderá ser concedida por apenas um deles, em
                caso de falecimento do outro ou se destituído do poder familiar.
                Nesse caso deverá ser apresentada uma certidão que comprove a
                situação.
              </small>
            </p>
            <p>
              <small>
                Obs 2: Também poderá ser concedida em caso de ausência de um dos
                pais, isto é, caso não se tenha notícia e nem seu paradeiro.
                Esta informação deverá ser declarada na escritura.
              </small>
            </p>
            <p>
              <small>
                Obs 3: Caso haja divergência quanto a vontade dos pais (um quer
                e o outro não), a emancipação não poderá ser feita no cartório,
                mas através de ação judicial específica. Procure a Defensoria
                Pública local ou o patrocínio de um advogado, neste caso.
              </small>
            </p>
            <p>b) Ter o menor, no mínimo, 16 anos de idade completos.</p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="https://bernardocesarcoura.jusbrasil.com.br/noticias/191229276/o-que-e-emancipacao"
                target="_blank"
              >
                <small>
                  {" "}
                  https://bernardocesarcoura.jusbrasil.com.br/noticias/191229276/o-que-e-emancipacao
                </small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
