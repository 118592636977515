import React from "react";
import { Galery } from "../components/Galery";

export const QuemSomos = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>Quem somos</h3>
        <span className="">Conheça mais sobre a nossa serventia.</span>
      </div>
      <div className="container quem-somos d-flex">
        <div className="holder w-50">
          <h2>Histórico</h2>
          <p>
            O Terceiro Registro Civil de Pessoas Naturais foi criado em 1970 por
            ato do Governo do Estado de Goiás, que nomeou Ary Oliveira Guimarães
            seu primeiro oficial. No ano 2000, após a edição de lei estadual, a
            serventia passou a exercer cumulativamente a atribuição de notas. Em
            2014, assumiu o atual oficial e tabelião titular após a aprovação em
            concurso público.
          </p>
        </div>

        <Galery />
      </div>
    </div>
  );
};
