import React from "react";
import missao from "../imgs/dartboard.png";
import visao from "../imgs/binoculars.png";
import valores from "../imgs/satisfaction.png";
import qualidade from "../imgs/controle-de-qualidade.png";

const Missao = () => {
  return (
    <div style={{ backgroundColor: "#5a8447" }}>
      <div className="container">
        <div className="missao-icon missao-desktop">
          <div>
            <img src={missao} alt="missão" />
            <h4>Missão</h4>
          </div>
          <div>
            <img src={visao} alt="visão" />
            <h4>Visão</h4>
          </div>
          <div>
            <img src={valores} alt="valores" />
            <h4>Valores</h4>
          </div>
          <div>
            <img src={qualidade} alt="politica da qualidade" />
            <h4>Política da Qualidade</h4>
          </div>
        </div>
        <div className="missao missao-desktop">
          <div>
            <p>
              "Prestar um serviço ágil e eficaz, baseado na ética e na segurança
              jurídica"
            </p>
          </div>
          <div>
            <p>
              “Ser reconhecido como um serviço extrajudicial de qualidade que
              atenda às exigências legais e satisfaça às necessidades do
              usuário".
            </p>
          </div>
          <div>
            <ul>
              <li>Ética e legalidade</li>
              <li>Comprometimento</li>
              <li>Segurança Jurídica</li>
              <li>Eficiência</li>
              <li>Confidencialidade</li>
            </ul>
          </div>
          <div>
            <p>
              “Comprometemo-nos a fornecer serviços notariais e registrais com
              excelência, garantindo segurança jurídica e satisfação do cliente,
              por meio da conformidade regulatória e da melhoria contínua do
              Sistema de Gestão da Qualidade.”
            </p>
          </div>
        </div>
        <div className="missao missao-mobile">
          <div>
            <img src={missao} alt="missão" />
            <h4>Missão</h4>
            <p>
              "Prestar um serviço ágil e eficaz, baseado na ética e na segurança
              jurídica"
            </p>
          </div>
          <div>
            <img src={visao} alt="visão" />
            <h4>Visão</h4>
            <p>
              “Ser reconhecido como um serviço extrajudicial de qualidade que
              atenda às exigências legais e satisfaça às necessidades do
              usuário".
            </p>
          </div>
          <div>
            <img src={valores} alt="valores" />
            <h4>Valores</h4>
            <ul>
              <li>Ética e legalidade</li>
              <li>Comprometimento</li>
              <li>Segurança Jurídica</li>
              <li>Eficiência</li>
              <li>Confidencialidade</li>
            </ul>
          </div>
          <div>
            <img src={valores} alt="politica da qualidade" />
            <h4>Política da Qualidade</h4>
            <p>
              “Comprometemo-nos a fornecer serviços notariais e registrais com
              excelência, garantindo segurança jurídica e satisfação do cliente,
              por meio da conformidade regulatória e da melhoria contínua do
              Sistema de Gestão da Qualidade.”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Missao;
