import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../imgs/logo.jpg";
import { Menu } from "./Menu";
import { MenuMobile } from "./MenuMobile";

export const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const collapseMenu = () => {
    setMenu(!menu);
  };

  return (
    <nav className="navbar navbar-expand-lg w-100 navbar-mobile">
      <div className="top-topo">
        <span>
          <i className="bi bi-alarm-fill"></i> Atendimento: Segunda à sexta das
          8h às 17h - Sábados das 8h às 12h
        </span>
        <span>
          <Link to="mailto:cartorio@terceirocartoriogo.com.br">
            <i className="bi bi-envelope-fill"></i> E-mail:
            cartorio@terceirocartoriogo.com.br
          </Link>
        </span>
        <span>
          <i className="bi bi-telephone-inbound-fill"></i> Telefones:{" "}
          <Link to="call:+556232251847">(62) 3225-1847</Link> |{" "}
          <Link to="call:6232293097">(62) 3229-3097 |</Link>
          <Link to="https://wa.me/5562998483097" target="_blank">
            {" "}
            (62) 9 9848-3097 <i className="bi bi-whatsapp"></i>
          </Link>
        </span>{" "}
      </div>
      <div className="logo-top">
        <img src={logo} alt="Logo Cartório" />
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={collapseMenu}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse nav-desktop w-100 justify-content-center"
        id="navbarSupportedContent"
      >
        <Menu />
      </div>
      {menu ? (
        <div className=" w-100 " id="">
          <MenuMobile
            collapseMenu={collapseMenu}
            menu={menu}
            setMenu={setMenu}
          />
        </div>
      ) : null}
    </nav>
  );
};
