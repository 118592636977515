import React from "react";

export const MaloteVirtual = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>MALOTE VIRTUAL</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>

            <p>
              É um sistema que consiste no envio de documentos autenticados
              como: contratos, cartas, certidões, ofícios, procurações,
              atestados, declarações para outros municípios, em tempo real,
              através da internet, usando ferramentas de certificação digital e
              criptografia.
            </p>

            <p>
              O cidadão vai até o cartório com os documentos originais e
              solicita o envio das cópias autenticadas para outra cidade. O
              cartório da outra cidade recebe eletronicamente o documento e
              entrega para a pessoa indicada.
            </p>

            <p>
              Com este serviço, a cópia digital autenticada eletronicamente é
              feita diretamente em um scanner, diminuindo o tempo de espera para
              os cidadãos e reduzindo os custos operacionais da Serventia.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
