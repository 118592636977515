import React from "react";
import { Link } from "react-router-dom";

export const Testamento = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>TESTAMENTO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              Testamento é o ato pelo qual a pessoa declara ao tabelião sua
              vontade, para depois de sua morte. Pode ser utilizado para
              disposições patrimoniais e não patrimoniais.
            </p>
            <p>
              Quem tem herdeiros necessários (filhos, netos, pais, avós, marido
              ou mulher) deve reservar a eles a legítima (metade dos bens)
              prevista em lei, mas poderá dispor, mediante testamento, da parte
              disponível de seus bens (a outra metade).
            </p>
            <p>
              <b>Atenção:</b> muitos litígios podem ser evitados mediante um
              planejamento sucessório feito através de testamento.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="http://www.cnbsp.org.br/index.php?pG=X19wYWdpbmFz&amp;idPagina=60016"
                target="_blank"
              >
                <small>
                  {" "}
                  http://www.cnbsp.org.br/index.php?pG=X19wYWdpbmFz&amp;idPagina=60016
                </small>
              </Link>
            </p>

            <hr />
            <h5>Testamento Público</h5>
            <p>
              É o ato de registro no Cartório de Notas, escrito na presença do
              Tabelião de Notas e testador, registrado em livro e folhas
              próprias, na presença de duas testemunhas que não podem ser
              parentes do testador ou do beneficiário envolvido no ato. É uma
              das formas mais seguras de se registrar um ato de Testamento,
              tendo em vista o arquivo permanente no livro do Cartório de Notas.
              O testador fica protegido e seguro que a sua vontade será
              realmente cumprida após seu falecimento.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="https://blog.cartorio24horas.com.br/testamento-saiba-como-registrar/"
                target="_blank"
              >
                <small>
                  {" "}
                  https://blog.cartorio24horas.com.br/testamento-saiba-como-registrar/
                </small>
              </Link>
            </p>

            <hr />
            <h5>Quais são os requisitos do testamento público?</h5>
            <p>
              O testamento público é um ato personalíssimo que deve ser feito
              pessoalmente pelo interessado perante um tabelião de notas.
            </p>
            <p>
              Qualquer pessoa, maior de 16 anos, que esteja em plena capacidade
              e em condições de expressar sua vontade perante o tabelião pode
              fazer um testamento público.
            </p>
            <p>
              A lei exige a presença de 2 (duas) testemunhas para o ato, as
              quais não podem ser parentes do testador nem do beneficiário.
            </p>

            <p>
              <b>Atenção:</b> o testamento público, diferentemente do testamento
              particular, é o mais seguro porque fica arquivado no livro do
              tabelião, e sua existência fica registrada no Registro Central de
              Testamentos (RCTO), módulo integrante da Censec, que é
              obrigatoriamente consultado para processar inventários judiciais
              ou lavrar escrituras públicas de inventarios.
            </p>

            <p>
              O inventário poderá ser lavrado em cartório de notas quando houver
              testamento, se todos os interessados forem capazes e concordes,
              nas seguintes hipóteses:{" "}
            </p>
            <ul className="fs-6-9">
              <li>
                com expressa autorização do juízo sucessório competente, nos
                autos do procedimento de abertura e cumprimento de testamento; e{" "}
              </li>
              <li>
                nos casos de testamento revogado, caduco ou declarado inválido
                por decisão judicial transitada em julgado. Se houver disposição
                no testamento reconhecendo filho ou qualquer outra declaração
                irrevogável, a lavratura de escritura é vedada e o inventário
                deverá ser feito judicialmente (Provimento CGJ nº 37/2016).
              </li>
            </ul>

            <hr />
            <h5>É possível alterar o conteúdo de um testamento?</h5>

            <p>
              Um testamento pode ser modificado ou revogado pelo testador, total
              ou parcialmente, a qualquer momento, por meio de outro testamento.
            </p>
            <p>
              Atenção: A cláusula de reconhecimento de filho em testamento é
              irrevogável.
            </p>

            <hr />
            <h5>Testamento cerrado</h5>
            <p>
              O testamento cerrado ou secreto é aquele escrito e assinado pelo
              próprio testador e aprovado pelo tabelião, na presença de 2 (duas)
              testemunhas.
            </p>
            <p>
              O tabelião não tem acesso ao conteúdo do documento e apenas lavra
              o auto de aprovação, lacra e costura o instrumento.
            </p>
            <p>
              <b>Atenção: </b>em caso de perda do testamento cerrado ou
              rompimento do lacre, ele não poderá ser cumprido, uma vez que não
              fica arquivado nos livros do tabelião nem no Registro Central de
              Testamentos (RCTO).
            </p>

            <hr />
            <h5>Testamento vital</h5>
            <p>
              É um instrumento que permite ao paciente, antecipadamente,
              expressar a sua vontade quanto às diretrizes de um tratamento
              médico futuro, caso fique impossibilitado de manifestar a sua
              vontade em virtude de acidente ou doença grave. Por este
              documento, por exemplo, é possível determinar que a pessoa não
              deseja submeter-se a tratamento para prolongamento da vida de modo
              artificial, às custas de sofrimento, ou ainda, deixar claro que
              recusa-se a receber transfusão de sangue em caso de acidente ou
              cirurgia. Na verdade, não se trata propriamente de um testamento
              mas de uma escritura pública de declaração porque o testamento
              somente vigora após a morte do testador.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="http://www.tabelcid.not.br/servicos/testamento-curitiba.aspx"
                target="_blank"
              >
                <small>
                  {" "}
                  http://www.tabelcid.not.br/servicos/testamento-curitiba.aspx
                </small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
