import React from "react";
import tabela from "../imgs/tabela-emolumentos_Página_1.jpg";
import { Link } from "react-router-dom";

export const CustasEmolumentos = () => {
  const storage =
    "/storage/Tabela Técnica de Reajuste de Emolumentos e Taxas dos Selos Eletrônicos 2023 (Atualizada até 16022023).pdf";
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>Tabela de Custas e Emolumentos</h3>
        <span className=""></span>
      </div>
      <div className="container quem-somos d-flex tabela-emolumentos">
        <Link
          to={`https://iuryflores.com.br/3rcgo/${storage}`}
          target="_blank"
        >
          <p className="btn btn-success d-flex align-items-center">
            <i class="bi bi-download fs-5"></i>
            <span>Clique aqui para baixar</span>
          </p>
        </Link>
        <img src={tabela} alt="Tabela de emolumentos imagem" />
      </div>
    </div>
  );
};
