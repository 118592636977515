import React from "react";

export const Obito = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>ÓBITO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              A declaração do óbito deverá ser feita no&nbsp;Cartório&nbsp;de
              Registro Civil da circunscrição do local do falecimento ou do
              último domicílio do falecido.
            </p>
            <p>
              O óbito deve ser levado a registro em até 24 horas, ou em 3 meses,
              contados da data do falecimento.
            </p>
            <p>
              Se o falecido for solteiro, deverá ser apresentada a sua certidão
              de nascimento. Se o falecido for casado, separado, divorciado ou
              viúvo, deverá ser apresentada a sua certidão de casamento.
            </p>
            <p>
              Os nomes dos eventuais filhos do falecido devem ser declarados,
              independentemente do estado civil do falecido.
            </p>

            <p>
              <strong>Documentos Necessários</strong>
            </p>

            <p>
              DO (Declaração de Óbito) – via amarela emitida pelo hospital/IML.
            </p>

            <p>
              O declarante deverá comparecer com a sua identificação original
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>
                São aceitos como “Documentos de Identificação”, desde que
                original:
              </strong>
            </p>

            <p>– Carteira de Identidade</p>
            <p>
              – Carteira de Identidade Profissional (advogado, médico,
              contadores, etc…)
            </p>
            <p>– Carteira Nacional de Habilitação</p>
            <p>– Carteira de Trabalho Digital</p>
            <p>– Passaporte</p>
            <p>&nbsp;</p>
            <p>
              <strong>Observação</strong>: As Carteiras de Identidade Funcional
              ou Passes Livre NÃO são aceitos como identificação.
            </p>

            <p>
              O declarante deverá, ainda, apresentar pelo menos um documento do
              falecido que comprove qualquer uma das informações abaixo:
            </p>
            <ol>
              <li>
                Número de registro de Carteira de Identidade e respectivo órgão
                emissor;
              </li>
              <li>Número do Cadastro de Pessoas Físicas (CPF);</li>
              <li>
                Número de Registro de Nascimento, com informação do livro, da
                folha e do termo;
              </li>
              <li>Número do Título de Eleitor;</li>
              <li>
                Número de inscrição no Instituto Nacional de Seguro Social –
                INSS, se contribuinte individual;
              </li>
              <li>Número de inscrição do PIS/PASEP;</li>
              <li>
                Número de benefício previdenciário-NB, se a pessoa falecida for
                titular de qualquer benefício pago pelo INSS;
              </li>
              <li>Número e série da Carteira de Trabalho.</li>
            </ol>
            <p>&nbsp;</p>
            <p>
              <strong>
                Informações necessárias que podem ser apresentadas por escrito:
              </strong>
            </p>

            <p>– Se o(a) falecido(a) deixou bens (sim ou não)</p>

            <p>– Se o(a) falecido(a) era eleitor (sim ou não)</p>

            <p>– Se o(a) falecido(a) deixou testamento (sim ou não)</p>

            <p>– Se os pais do(a) falecido(a) são vivos ou falecidos</p>

            <p>– Naturalidade dos pais do(a) falecido(a)</p>

            <p>– Endereço residencial do(a) falecido(a)</p>

            <p>
              – Nome (primeiro nome) dos filhos vivos e falecidos do(a)
              falecido(a)
            </p>

            <p>– Profissão do(a) falecido(a)</p>

            <p>– Estado Civil do(a) falecido(a)</p>

            <p>
              – Nome e cidade do cemitério onde o(a) falecido(a) será
              sepultado(a) ou cremado(a)
            </p>
         
            <p>– Endereço residencial do(a) declarante</p>
          
            <p>– Contato do(a) declarante</p>
          </div>
        </div>
      </section>
    </div>
  );
};
