import React from "react";
import { Link } from "react-router-dom";

export const ReconhecimentoPaternidade = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>RECONHECIMENTO DE PATERNIDADE</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              É quando os pais ou apenas um deles declara e assume que
              determinado indivíduo é seu descendente biológico. Segundo o
              Código Civil, o reconhecimento de filho é irrevogável (artigo
              1609), salvo em casos de inequívoca comprovação de que o
              reconhecedor foi induzido a erro (exame de DNA, testemunhas,
              documentos etc).
            </p>
            <h5>Como fazer?</h5>
            <p>
              O reconhecimento está disponível nos cartórios extrajudiciais,
              confira as recomendações.
            </p>
            <p>
              <strong>
                Reconhecimento de paternidade no cartório de registro civil –
                genitora (mãe) ou responsável:
              </strong>{" "}
              A genitora ou responsável pode requerer o reconhecimento de
              paternidade em qualquer cartório de registro civil, independente
              de onde tenha sido realizado o registro inicial do filho. Será
              necessário o preenchimento de um formulário disponível no próprio
              cartório, após, o caso será conduzido pelo Ministério Público em
              ação de investigação de paternidade, acumulando o pedido de
              alimentos.
            </p>
            <p>
              <strong>
                Reconhecimento de paternidade no cartório de registro civil –
                genitor (pai):
              </strong>{" "}
              O genitor pode requerer o reconhecimento de paternidade tardia
              espontânea, diretamente no cartório de registro civil,
              independente do cartório que registrou o nascimento do filho,
              porém a genitora deverá acompanhar a manifestação desta
              informação, em caso do filho menor de idade. Os pais deverão estar
              munidos de seus documentos pessoais originais e a certidão de
              nascimento original do filho.{" "}
            </p>
            <p>
              Caso o filho já tenha atingido a maioridade, o genitor e o filho
              deverão comparecer no cartório, munidos de seus documentos
              pessoais e originais, certidão de nascimento original do
              registrado, comprovantes de residência e certidões dos
              distribuidores forenses (da Justiça Estadual – distribuição
              criminal execuções criminais; da Justiça Federal – distribuição
              cível e criminal e execuções criminais; certidão de protesto no
              Cartório de Protesto e antecedentes criminais). O procedimento do
              reconhecimento da paternidade no cartório de registro civil não
              gera custas, as custas geradas serão para a emissão de uma nova
              certidão no cartório de registro civil onde foi feito o registro
              original do filho, constando a averbação do reconhecimento.
            </p>
            <p>
              O valor da escritura pública é tabelado pela Tabela de Custas
              Extrajudiciais disposta pela Corregedoria Nacional da Justiça e
              disponível para consulta em todos os portais dos Tribunais de
              Justiça dos estados brasileiros.
            </p>
            <p>
              <hr />
            </p>
            <h5>
              Como fazer o Reconhecimento de Paternidade Socioafetiva em
              cartório (ou Reconhecimento de Maternidade)
            </h5>

            <p>
              É o procedimento de regularizar o reconhecimento da paternidade
              socioafetiva, que é a prática de reconhecer o acolhimento de
              criança, adolescentes e adulto como filho.
            </p>
            <p>
              Basta quem fará o reconhecimento socioafetivo comparecer ao
              Cartório de Registro Civil das Pessoas Naturais mais próximo,
              munido de documento de identidade com foto e certidão de
              nascimento (em caso do reconhecimento se dar no mesmo ofício do
              registro de nascimento da pessoa a ser reconhecida, a certidão de
              nascimento poderá ser expedida no ato) do filho a ser reconhecido.
            </p>
            <p>
              O pai ou a mãe (socioafetivos) deverá ser maior de 18 anos, sendo
              o estado civil irrelevante (pode ser solteiro, casado, divorciado,
              etc).
            </p>
            <p>
              O reconhecimento poderá ser feito em cartório diverso daquele em
              que o filho a ser reconhecido foi registrado originalmente.
            </p>
            <p>
              Por outro lado, se o filho a ser reconhecido for maior de 12 anos,
              o próprio deverá concordar ou não, por meio de assinatura no termo
              específico.
            </p>
            <p>
              Após, o oficial do cartório analisará a documentação
              minuciosamente e dará prosseguimento ao procedimento, se tudo
              estiver certo.
            </p>
            <p>
              Fonte consultada:
              <Link
                to="https://guiadocumentos.com.br/reconhecimento-de-paternidade-socioafetiva-em-cartorio/"
                target="_blank"
              >
                https://guiadocumentos.com.br/reconhecimento-de-paternidade-socioafetiva-em-cartorio/
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
