import React, { useState } from "react";
import "./Cookies.css";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

function CookiesAlert() {
  const [setAccepted] = useState(false);
  return (
    <CookieConsent
      location="bottom"
      buttonText="Prosseguir"
      cookieName="Aviso de Privacidade"
      buttonStyle={{ color: "#000", fontSize: "13px", borderRadius: "5px" }}
      expires={1}
      className="cookies-alert"
      onAccept={(acceptedByScrolling) => {
        if (acceptedByScrolling) {
          // triggered if user scrolls past threshold
          setAccepted(true);
          localStorage.setItem("cookiesAccepted", true);
        }
      }}
    >
      <i class="bi bi-shield-fill-check fs-4"></i>
      <h5>Aviso de privacidade</h5>
      <p>
        O 3º Registro Civil e Tabelionato de Goiânia-GO utiliza cookies e outras
        tecnologias semelhantes para melhorar a sua experiência de acordo com a
        nossa Política de Privacidade, e ao continuar navegando você concorda
        com estas condições.
        <Link
          to="https://iuryflores.com.br/3rcgo/AvisoGeraldePrivacidadeeCookies.pdf"
          target="_blank"
          style={{ fontSize: "10px" }}
        >
          {" "}
          Saiba mais.
        </Link>
      </p>
    </CookieConsent>
  );
}

export default CookiesAlert;
