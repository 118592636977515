import React from "react";
import { CarouselComp } from "../components/Carousel";
import Missao from "../components/Missao";
import Servicos from "../components/Servicos";

export const Home = () => {
  return (
    <div>
      <CarouselComp />
      <div className="conteudo-pagina">
        <div className="servicosContainer holder flexbox">
          <h2>
            Principais Serviços<i className="bi bi-vector-pen"></i>
          </h2>
          <Servicos
            title="E-Notariado"
            desc="O e-Notariado é a plataforma digital gerida pelo Colégio
                Notarial do Brasil – Conselho Federal, que conecta os usuários
                aos serviços oferecidos pelos cartórios de notas em todo o
                Brasil. A partir da publicação do Provimento nº 100/2020,
                cidadãos de todo o País podem realizar atos notariais de forma
                online, por meio da plataforma e-Notariado, que oferece
                segurança jurídica e os mesmos efeitos de um ato realizado de
                forma presencial no cartório de notas. Todo ato notarial online
                contará com videoconferência entre o requerente e o tabelião, e
                a assinatura da parte por meio de certificado digital."
            link="/servicos/e-notariado/"
          />
          <Servicos
            title="Nascimento"
            desc="Certidão de Nascimento, além de ser um documento de
                identificação, é a primeira garantia de cidadania e direitos a
                todos os brasileiros."
            link="/servicos/nascimento/"
          />
          <Servicos
            title="Casamento"
            desc="O casamento civil é uma formalidade solene exigida pela lei a
                qual confere a união de duas pessoas em comunhão plena de vida
                calcada na igualdade de direitos e deveres."
            link="/servicos/casamento/"
          />
          <Servicos
            title="Escritura pública"
            desc=" A escritura pública é o documento que representa a declaração de
                vontade de uma pessoa ou o negócio de várias pessoas ou
                empresas."
            link="/servicos/escritura-publica/"
          />
          <Servicos
            title="Procuração"
            desc="Procuração é o instrumento pelo qual uma pessoa nomeia outra de
                sua confiança como seu representante (procurador), para agir em
                seu nome em determinada situação em que não possa estar
                presente."
            link="/servicos/procuracao/"
          />
          <Servicos
            title="Apostilamento de Haia"
            desc="Para um documento receber o certificado da Apostila, basta
                levá-lo a um dos cartórios que oferecem esse serviço."
            link="/servicos/apostilamento-haia/"
          />
          <Servicos
            title="Autenticação"
            desc="As cópias autenticadas por Tabelião, em meio digital ou em
                papel, têm o mesmo valor de prova que os originais, e fazem
                prova plena para todos os efeitos legais. A autenticação é feita
                após a conferência da cópia com o documento original, existente
                no Tabelionato ou exibida pelo apresentante, ou ainda se for
                conferida por outro tabelião."
            link="/servicos/autenticacao/"
          />
          <Servicos
            title="Reconhecimento de Firma"
            desc="Quando o tabelião reconhece a firma de alguém declara que a
                assinatura da pessoa é da pessoa ou, ao menos, semelhante."
            link="/servicos/reconhecimento-de-firma/"
          />
        </div>
      </div>
      <Missao />
    </div>
  );
};
