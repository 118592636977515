import React from "react";
import { Link } from "react-router-dom";

export const EscrituraPublicaDoacao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>ESCRITURA PÚBLICA DE DOAÇÃO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              A Escritura Pública de Doação é o ato feito e assinado em
              Tabelionato de Notas por meio do qual uma das partes doa
              determinado bem, móvel ou imóvel, para outra.
            </p>

            <p>
              <b>
                Atenção: geralmente a doação é gratuita, mas também pode ser
                onerosa, ou seja, pode ser estipulada uma contraprestação, como
                por exemplo, o compromisso de se construir uma escola no terreno
                doado.
              </b>
            </p>
            <hr />
            <h5>Como é feita?</h5>

            <p>
              A escritura de doação deve ser agendada com o tabelião ou com um
              de seus escreventes, sendo recomendável que a parte faça o
              agendamento pessoalmente para entregar a documentação que possui e
              ser orientada sobre a necessidade de reunir outros documentos.{" "}
            </p>
            <p>
              Na data marcada, as partes comparecerão ao tabelionato de notas,
              munidas de seus documentos pessoais originais, para assinar a
              escritura. A assinatura da escritura será feita por todas as
              partes no mesmo momento. Aquele que vai receber o bem em doação
              também precisa estar presente, para aceitar o bem doado, exceto
              quando for doação pura para pessoa absolutamente incapaz.
            </p>
            <p>
              A escritura pública é obrigatória para a transferência de bens
              imóveis de valor superior a 30 salários mínimos.
            </p>

            <p>
              <b>
                Atenção: depois de lavrada a escritura de doação do imóvel, ela
                deve ser registrada no cartório de Registro de Imóveis. Você
                pode solicitar que o próprio tabelionato providencie esse
                trâmite junto ao registro imobiliário. Somente depois do
                registro a propriedade fica de fato transferida à pessoa do
                donatário.
              </b>
            </p>
            <hr />
            <h5>Documentos Pessoais</h5>

            <p>Doadores Pessoa Física:</p>
            <ul className="fs-6-9">
              <li>
                Fotocópia do RG e CPF, inclusive dos cônjuges (e apresentação do
                original);
              </li>
              <li>
                Certidão de Casamento: se casado, separado, divorciado ou viúvo;
              </li>
              <li>Pacto antenupcial registrado, se houver;</li>
              <li>Certidão de óbito;</li>
              <li>Informar endereço;</li>
              <li>Informar profissão.</li>
            </ul>

            <p>Doadores Pessoa Jurídica:</p>
            <ul className="fs-6-9">
              <li>Número do CNPJ para obtenção da certidão via internet;</li>
              <li>
                Fotocópia autenticada do contrato ou estatuto social, última
                alteração e alteração em que conste modificação na diretoria;
              </li>
              <li>Certidão Conjunta de Débitos da Receita Federal (PGFN);</li>
              <li>Certidão Negativa de Débitos (CND) do INSS;</li>
              <li>
                RG, CPF, profissão e residência do diretor, sócio ou procurador
                que assinará a escritura;
              </li>
              <li>
                Certidão da junta comercial de que não há outras alterações
              </li>
            </ul>

            <p>Donatários:</p>
            <ul className="fs-6-9">
              <li>
                Fotocópia do RG e CPF, inclusive dos cônjuges (e apresentação do
                original);
              </li>
              <li>
                Certidão de Casamento: se casado, separado, divorciado ou viúvo;
              </li>
              <li>Pacto antenupcial registrado, se houver;</li>
              <li>Certidão de óbito;</li>
              <li>Informar endereço;</li>
              <li>Informar profissão;</li>
            </ul>

            <p>
              <b>
                Atenção: o cônjuge deve ter CPF individual próprio. Se a doação
                for feita em favor de filho menor incapaz, ele também deverá ter
                CPF próprio.
              </b>
            </p>

            <p>
              Se o casal for casado sob o regime da comunhão universal, da
              separação total ou participação final dos aquestos, é necessário o
              prévio registro do pacto antenupcial no cartório de Registro de
              Imóveis do domicílio dos cônjuges.
            </p>
            <p>- Documentos dos bens móveis:</p>
            <p>
              No caso de bem móvel, deve ser levado ao tabelionato documento que
              descreva o bem e de onde se possa apurar seu valor, por exemplo,
              documento do carro e valor nos termos da tabela FIPE
            </p>
            <p>
              Caso o bem não possua documento específico, como joias, máquinas e
              outros, o vendedor descreverá o bem e declarará o valor.
            </p>

            <p>
              <small>
                Atenção: se a doação for de quotas ou ações de determinada
                empresa é importante que seja apresentado o balanço patrimonial.
              </small>
            </p>
            <hr />
            <h5>Documentos dos bens imóveis</h5>
            <p>Urbano – Casa ou Apartamento:</p>
            <ul className="fs-6-9">
              <li>
                Certidão de matrícula ou transcrição atualizada no momento da
                assinatura da escritura (prazo de 30 dias a partir da data de
                expedição);
              </li>
              <li>Certidão de quitação de tributos imobiliários;</li>
              <li>Carnê do IPTU do ano vigente;</li>
              <li>Informar o valor da doação.</li>
            </ul>

            <p>Rural</p>
            <ul className="fs-6-9">
              <li>
                Certidão de matrícula ou transcrição atualizada (prazo de 30
                dias a partir da data de expedição). A certidão deve estar
                atualizada no momento da lavratura da escritura, e não no
                momento da entrega dos documentos no cartório;
              </li>
              <li>
                Certidão de regularidade fiscal do imóvel emitida pela
                Secretaria da Receita Federal;
              </li>
              <li>CCIR – Certificado de Cadastro de Imóvel Rural;</li>
              <li>
                5 (cinco) últimos comprovantes de pagamento do ITR – Imposto
                Territorial Rural;
              </li>
              <li>DITR – Declaração do Imposto sobre a Propriedade Rural;</li>
              <li>Informar o valor da doação.</li>
            </ul>

            <p>Outros documentos</p>
            <ul className="fs-6-9">
              <li>
                Procuração de representantes. Prazo: 90 dias. Se a procuração
                for feita em cartório de outra cidade, deve apresentar firma
                reconhecida do oficial que a expediu;
              </li>
              <li>
                Substabelecimento de procuração. Prazo: 90 dias. Se feita em
                cartório de outra cidade, deve apresentar firma reconhecida do
                oficial que a expediu;
              </li>
              <li>
                Alvará judicial original, se for necessário para o caso
                concreto.
              </li>
            </ul>
            <hr />
            <h5>Doação com reserva de usufruto</h5>
            <p>
              Na doação com reserva de usufruto transmite-se somente a
              nua-propriedade para o donatário, sendo que o usufruto fica
              reservado ao doador. Isso significa que o doador tem o direito
              permanecer no uso e no gozo do imóvel pelo prazo estipulado, que
              pode ser vitalício.
            </p>
            <hr />
            <h5>Quanto custa?</h5>
            <p>
              O preço é tabelado por lei. Para verificar os valores, consulte o
              tabelião de sua escolha ou acesse a tabela de custas.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link to="http://www.cnbsp.org.br/" target="_blank">
                <small> http://www.cnbsp.org.br/</small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
