import React from "react";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export const AvisodePrivacidade = () => {
  const docs = [
    {
      uri: require("./AvisodePrivacidade.pdf")
    }
  ];

  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>
          Aviso de Privacidade
          <br />e Cookies
        </h3>
        <span className=""></span>
      </div>

      <div className="pdf-container">
        <DocViewer
          documents={docs}
          /* config={{
            header: {
              disableHeader: true,
              disableFileName: false,
              retainURLParams: false
            },
            csvDelimiter: ",",
            pdfZoom: { defaultZoom: 1.1, zoomJump: 0.2 },
            pdfVerticalScrollByDefault: true
          }}
          style={{ width: "100%", height: "100%", marginTop: "-300px" }}*/
          pluginRenderers={DocViewerRenderers}
        />
      </div>
    </div>
  );
};
