import React from "react";

export const EscrituraPublica = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>ESCRITURA PÚBLICA</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              A escritura pública pode ser usada para formalizar qualquer
              negócio jurídico. Em alguns casos, porém, a lei irá exigir como
              requisito de validade do negócio que ele seja celebrado por
              escritura pública. Isso ocorre por razões de ordem pública. A
              intervenção do tabelião em qualquer caso conferirá fé pública aos
              negócios, dando-lhes força probatória plena, além de evitar
              problemas e nulidades.
            </p>
            <p>
              Ao tabelião cabe ouvir o desejo das partes, aconselhando-as de
              modo a achar a melhor solução jurídica para o caso. Isto perpassa
              uma prevenção contra eventuais ilícitos, possíveis nulidades e
              causas de anulação do negócio. Ao ajustar a vontade das partes às
              exigências do ordenamento jurídico, o tabelião lavra a escritura
              no livro de notas. As cópias autênticas da escritura denominam-se
              traslado, a primeira delas, e certidão, as demais.
            </p>
            <p>&nbsp;</p>
            <p>
              Os atos mais frequentes retratados por instrumento público são:
            </p>
            <p>– Contratos de compra e venda de imóveis</p>
            <p>– Doação de bens</p>
            <p>– Procurações</p>
            <p>– Testamentos</p>
            <p>– Hipotecas</p>
            <p>– Quitações</p>
            <p>– Separações e divórcios consensuais</p>
            <p>– Reconhecimento de filhos</p>
            <p>– Emancipações</p>
            <p>– Inventários e partilhas</p>
            <p>– Ata Notarial</p>
            <p>&nbsp;</p>
            <p>
              O Tabelião procura prever todas as possíveis consequências do
              contrato na escritura pública, prevenindo discussões e litígios.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
