import React from "react";

export const Casamento = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>CASAMENTO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              <strong>Casamento</strong>
            </p>
            <p>
              O casamento é ato formal e solene que se realiza no momento em que
              duas pessoas manifestam, perante o juiz, a sua vontade em
              estabelecer vínculo conjugal e o juiz os declara casados.
            </p>
            <p>
              O casamento civil, bem como o registro civil de casamento
              religioso, é precedido de processo de habilitação, no qual os
              interessados, apresentando os documentos exigidos pela lei civil,
              requerem ao Oficial de Registro Civil das Pessoas Naturais da
              circunscrição de residência de um dos nubentes, que lhes expeça
              certificado de habilitação para o casamento. Para o casamento ser
              habilitado neste cartório, é necessário que pelo menos um dos
              noivos resida em Goiânia.
            </p>
            <p>
              A celebração do casamento ocorre no dia, hora e lugar previamente
              designados pela autoridade que houver de presidir o ato, mediante
              escolha dos noivos. Pode ser realizada na sede da serventia ou em
              outro local escolhido pelos noivos, mas obrigatoriamente dentro da
              circunscrição.
            </p>
            <p>
              <strong>Documentos necessários</strong>
            </p>
            <p>
              O requerimento de habilitação é firmado por ambos os nubentes, de
              próprio punho ou por procurador, acompanhados de 2 (duas)
              testemunhas maiores de idade, que os conheçam e portem documentos
              de identificação originais.
            </p>
            <p>
              Os nubentes deverão apresentar os seguintes documentos originais e
              sem rasuras:
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Solteiros – Maiores de 18 anos</strong>
            </p>
            <p>– Documento de Identificação;</p>
            <p>– CPF;</p>
            <p>
              – Certidão de nascimento original, expedida há menos de 6 (seis)
              meses.
            </p>

            <p>&nbsp;</p>
            <p>
              <strong>Solteiros – Maiores de 16 e menores de 18 anos</strong>
            </p>
            <p>– Documento de Identificação;</p>
            <p>-CPF;</p>
            <p>
              – Certidão de Nascimento original, expedida há menos de 6 (seis)
              meses;
            </p>
            <p>
              – Data, local de nascimento e endereço de residência dos pais;
            </p>
            <p>– Devem estar acompanhados dos pais, para o consentimento.</p>

            <p>&nbsp;</p>
            <p>
              <strong>Solteiros – Menores de 16 anos</strong>
            </p>
            <p>
              – Art. 1.520.&nbsp; Não será permitido, em qualquer caso, o
              casamento de quem não atingiu a idade núbil, observado o disposto
              no art. 1.517 deste Código. Lei 10.406/2002 Código Civil.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Divorciados</strong>
            </p>
            <p>– Documento de Identificação;</p>
            <p>– CPF;</p>
            <p>
              -Certidão de Casamento original com a Averbação de Divórcio,
              expedida há menos de 6 (seis) meses;
            </p>
            <p>
              -Formal de Partilha, para comprovar que os bens foram partilhados
              (Caso essa circunstância conste à margem da averbação, dispensa-se
              a apresentação do formal);
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Viúvos</strong>
            </p>
            <p>– Documento de Identificação;</p>
            <p>– CPF;</p>
            <p>
              – Certidão de Casamento com anotação do óbito e Certidão de Óbito
              do cônjuge falecido, expedida há menos de 6 (seis) meses;
            </p>
            <p>
              – Inventário Positivo (se teve bens a partilhar) ou Negativo (se
              não teve bens a partilhar).
            </p>

            <p>&nbsp;</p>
            <p>
              <strong>REGIME DE BENS</strong>
            </p>
            <p>
              Antes de celebrado o casamento, é facultado aos nubentes estipular
              o que lhes convier em relação aos seus bens mediante escritura
              pública de pacto antenupcial. Como regra geral, o Código Civil
              estabelece o regime de comunhão parcial de bens, que regerá os
              efeitos patrimoniais do casamento se os nubentes não pactuarem em
              contrário em escritura pública. Em qualquer caso, o regime de bens
              só começará a vigorar a partir da celebração do casamento.
            </p>
            <p>
              <strong>regime de comunhão parcial de bens</strong>
            </p>
            <p>
              Os bens adquiridos após a data do casamento serão comuns ao casal.
              A lei cria, porém, uma série de exceções a essa regra geral.
              Assim, não se comunicam:&nbsp; os bens que os cônjuges vierem a
              adquirir por doação ou sucessão, bem como os sub-rogados em seu
              lugar; os bens adquiridos com valores exclusivamente pertencentes
              a um dos cônjuges em sub-rogação dos bens particulares; as
              obrigações anteriores ao casamento; as obrigações provenientes de
              atos ilícitos, salvo reversão em proveito do casal; os bens de uso
              pessoal, os livros e instrumentos de profissão; os proventos do
              trabalho pessoal de cada cônjuge; as pensões, meios-soldos,
              montepios e outras rendas semelhantes (Código Civil, artigos 1.658
              e 1.659). Não é necessária a Escritura Pública de Pacto
              Antenupcial.
            </p>
            <p>
              <strong>regime de separação de bens</strong>
            </p>
            <p>
              Os bens não se comunicam e a administração será exclusiva de cada
              um dos cônjuges, dispensando-se a anuência do outro cônjuge para
              fins de compra e venda de imóveis. Há necessidade de Escritura
              Pública de Pacto Antenupcial
            </p>
            <p>
              <strong>regime de comunhão universal</strong>
            </p>
            <p>
              Neste regime, estabelece-se, como regra geral, que todos os bens
              antes e após a data do casamento serão comuns ao casal. Toda
              herança familiar que um dos cônjuges adquirir será partilhada pela
              metade com o outro cônjuge. São exceções à regra geral os bens
              adquiridos por doação ou herança com cláusula de
              incomunicabilidade, assim como as dívidas anteriores ao casamento
              contraídas em proveito exclusivo de um dos cônjuges. Deverá ser
              apresentada a Escritura Pública de Pacto Antenupcial.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
