import React from "react";
import { Link } from "react-router-dom";

export const Apostilamento = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>APOSTILAMENTO DE HAIA</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <p>
              Para um documento receber o certificado da Apostila, basta levá-lo
              a um dos cartórios que oferecem esse serviço.
            </p>
            <p>
              O apostilamento inclui uma via física e outra eletrônica. A
              primeira será enviada junto ao documento, colada ou apensada. A
              segunda fica registrada em sistema próprio e será utilizada tanto
              para o controle das autoridades brasileiras quanto para a consulta
              de autoridades estrangeiras sobre as Apostilas emitidas no Brasil.
              Os interessados estrangeiros poderão consultar a autenticidade da
              Apostila emitida no Brasil por meio de um código de barras
              bidimensional que pode ser facilmente escaneado usando telefones
              celulares equipados com câmera (QR Code) e deverá ser inserido na
              própria Apostila física.
            </p>
            <p>
              Os documentos estrangeiros, uma vez apostilados no exterior,
              também estarão habilitados a produzir efeitos no Brasil, junto às
              autoridades, órgãos e instituições interessadas, não havendo mais
              envolvimento do Ministério das Relações Exteriores. A autoridade
              competente para apostilamento no exterior varia de país para país.
            </p>

            <h2>O que é a Apostila?</h2>
            <p>
              A Apostila é um certificado de autenticidade emitido por países
              signatários da Convenção da Haia, que é colocado em um documento
              público para atestar sua origem (assinatura, cargo de agente
              público, selo ou carimbo de instituição). Esse documento público
              apostilado será apresentado em outro país, também signatário da
              Convenção da Haia, uma vez que a Apostila só é válida entre países
              signatários.
            </p>

            <h2>O que é a Convenção da Haia?</h2>
            <p>
              A Convenção da Haia de 05 de outubro de 1961, sobre a Eliminação
              da Exigência da Legalização de Documentos Públicos Estrangeiros
              (também conhecida como Convenção da Apostila) é um Tratado
              Internacional que visa simplificar o processo de autenticação de
              documentos a serem usados no exterior. Foi promulgada no Brasil
              pelo Decreto 8.666/2016. A partir de sua entrada em vigor, em 16
              de agosto de 2016, ao invés de percorrer toda uma cadeia de
              legalização, os cidadãos dos países signatários devem recorrer a
              um único procedimento, que consiste na emissão da Apostila.
            </p>
            <p>
              <small>Fonte consultada: Fonte: CNJ</small>
            </p>

            <h2>Exemplos de documentos</h2>
             <ul className="fs-6-9">
              <li>
                Certidões de Registro Civil: nascimento, casamento, óbito, etc;
              </li>
              <li>
                Documentos públicos não emitidos por Cartórios: diplomas,
                históricos escolares, CNN's, certificados, certidões de nada
                consta, certidões de órgãos públicos ou entidades de classe,
                traduções juramentadas, etc;
              </li>
              <li>
                Documentos particulares: contratos, declarações particulares,
                procurações particulares, balanços patrimoniais, etc.
              </li>
              <li>
                Documentos elaborados pelos agentes diplomáticos ou consulares;
              </li>
              <li>
                Documentos administrativos relacionados diretamente com uma
                operação comercial ou aduaneira.
              </li>
            </ul>

            <h2>Uso</h2>
             <ul className="fs-6-9">
              <li>Solicitar dupla cidadania;</li>
              <li>Validar curso no exterior;</li>
              <li>Comprovar situação cível, criminal ou fiscal no exterior;</li>
              <li>Validar contabilidade empresarial no exterior;</li>
            </ul>
            <p>
              <small>Fonte consultada: </small>
              <Link to="http://anoreg.org.br/" target="_blank">
                <small>http://anoreg.org.br</small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
