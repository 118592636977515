import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import api from "../utils/api.utils";
import loadingGif from "../imgs/loading-state.gif";

export const LgpdForm = ({ message, setMessage }) => {
  const navigate = useNavigate();

  const [nome, setNome] = useState();
  const [documentos, setDocumentos] = useState();
  const [email, setEmail] = useState();
  const [celular, setCelular] = useState();

  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (e) => {
    setShow(false);
    setOutroSelected("");
    setSelectedOption(e.target.value);
  };

  const [outroSelected, setOutroSelected] = useState("");
  const handleOutro = (e) => {
    setShow(true);
    setSelectedOption(e.target.value);
  };

  const [show, setShow] = useState(false);

  const [descricao, setDescricao] = useState("");

  const [selectedInfo, setSelectedInfo] = useState("");
  const handleInfoChange = (e) => {
    setShow(false);
    setSelectedInfo(e.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setAviso(!aviso);
  };

  const [aviso, setAviso] = useState(false);

  //HANDLE SUBMIT
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      nome &&
      email &&
      documentos &&
      celular &&
      selectedInfo &&
      descricao &&
      selectedOption &&
      isChecked
    ) {
      try {
        setMessage(null);
        setLoading(true);
        setError(null);
        await api.sendAviso({
          nome,
          email,
          celular,
          documentos,
          descricao,
          selectedInfo,
          selectedOption,
          outroSelected,
          isChecked,
        });

        setMessage("Obrigado! Sua mensagem foi enviada!");
        setLoading(false);
        setNome("");
        setEmail("");
        setDocumentos("");
        setCelular("");
        setDescricao("");
        setSelectedInfo("");
        setSelectedOption("");
        setIsChecked(false);
        navigate("/solicitacao-dados/");
      } catch (error) {
        showMessage(error);
      }
    } else {
      setError(
        "Campo nome, email, assunto, tipo de manifestação e mensagem são obrigatórios!"
      );
    }
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const showMessage = (error) => {
    setError(error);
    setTimeout(() => {
      setError("");
    }, 6000);
  };
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 60000);
  }, [message, setMessage]);
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>Solicitação de Dados</h3>
      </div>
      <div className="pdf-container" style={{ marginTop: "-260px" }}>
        <form
          className="nobottommargin"
          id="contactform"
          name="contactform"
          onSubmit={handleSubmit}
        >
          <h2>
            FORMULÁRIO PARA EXERCÍCIO DE DIREITOS TITULAR DE DADOS PESSOAIS
          </h2>
          <h4>Lei Geral de Proteção de Dados</h4>
          <p className="text-justify">
            De acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD) – Lei
            13.709/2018, o Tabelião Rômulo Filizzola Nogueira, responsável pelo
            Terceiro Cartório de Registro Civil e Tabelionato de Notas de
            Goiânia-GO, disponibiliza o formulário abaixo para que o Requerente
            fique ciente que para exercer o seu direito de acesso à informação,
            os dados do Titular estão protegidos e por este motivo o Requerente
            está sujeito à responsabilidade civil e criminal pela utilização
            indevida ou ilícita dos dados obtidos perante referido Cartório:
          </p>
          <p style={{ color: "red" }}>
            Os campos marcados com * são de preenchimento obrigatório.
          </p>
          <p></p>
          <div className="form-group">
            <label htmlFor="nome">Nome completo:*</label>
            <input
              type="text"
              name="nome"
              value={nome}
              className="form-control"
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="documentos">RG e CPF:*</label>
            <input
              type="text"
              name="documentos"
              value={documentos}
              className="form-control"
              onChange={(e) => setDocumentos(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-mail válido:*</label>
            <input
              type="email"
              name="email"
              value={email}
              className="form-control"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="celular">Telefone celular:*</label>
            <input
              type="phone"
              name="celular"
              value={celular}
              className="form-control"
              onChange={(e) => setCelular(e.target.value)}
            />
          </div>
          <p className="mt-3">
            <strong>Quem solicita é?</strong>
          </p>
          <div>
            <input
              type="radio"
              id="option1"
              name="options"
              value="Cliente/Usuário"
              checked={selectedOption === "Cliente/Usuário"}
              onChange={handleOptionChange}
            />{" "}
            <label htmlFor="option1">Cliente/Usuário</label>
          </div>
          <div>
            <input
              type="radio"
              id="option2"
              name="options"
              value="Colaborador"
              checked={selectedOption === "Colaborador"}
              onChange={handleOptionChange}
            />{" "}
            <label htmlFor="option2">Colaborador</label>
          </div>
          <div>
            <input
              type="radio"
              id="option3"
              name="options"
              value="Ex Colaborador"
              checked={selectedOption === "Ex Colaborador"}
              onChange={handleOptionChange}
            />{" "}
            <label htmlFor="option3">Ex Colaborador</label>
          </div>
          <div>
            <input
              type="radio"
              id="option4"
              name="options"
              value="Fornecedor/Prestador"
              checked={selectedOption === "Fornecedor/Prestador"}
              onChange={handleOptionChange}
            />{" "}
            <label htmlFor="option4">Fornecedor/Prestador de Serviços</label>
          </div>
          <div>
            <input
              type="radio"
              id="option5"
              name="options"
              value="Outro"
              checked={selectedOption === "Outro"}
              onChange={handleOutro}
            />{" "}
            <label htmlFor="option5">Outro</label>{" "}
            {show && (
              <input
                type="text"
                id="outro"
                name="outro"
                value={outroSelected}
                onChange={(e) => setOutroSelected(e.target.value)}
              />
            )}
          </div>
          <p></p>
          <div className="form-group">
            <label htmlFor="descricao">
              <strong>* Descrição da solicitação e finalidade:</strong>
            </label>
            <textarea
              id="descricao"
              className="form-control"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              rows={5}
            />
          </div>
          <p className="mt-3">
            <strong>
              * Em razão da Lei Geral de Proteção de Dados Pessoais (LGPD) o
              Requerente solicita que os dados acima sejam informados da
              seguinte forma:
            </strong>
          </p>
          <div>
            <input
              type="radio"
              id="info1"
              name="informacao"
              value="Confirmação de existência de tratamento"
              checked={
                selectedInfo === "Confirmação de existência de tratamento"
              }
              onChange={handleInfoChange}
            />{" "}
            <label htmlFor="info1">
              Confirmação de existência de tratamento
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="info2"
              name="informacao"
              value="Acesso completo aos dados"
              checked={selectedInfo === "Acesso completo aos dados"}
              onChange={handleInfoChange}
            />{" "}
            <label htmlFor="info2">Acesso completo aos dados</label>
          </div>{" "}
          <div>
            <input
              type="radio"
              id="info3"
              name="informacao"
              value="Correção ou atualização dos dados"
              checked={selectedInfo === "Correção ou atualização dos dados"}
              onChange={handleInfoChange}
            />{" "}
            <label htmlFor="info3">Correção ou atualização dos dados</label>
          </div>{" "}
          <div>
            <input
              type="radio"
              id="info4"
              name="informacao"
              value="Anonimização, bloqueio ou eliminação de dados tratados em desconformidade com a lei"
              checked={
                selectedInfo ===
                "Anonimização, bloqueio ou eliminação de dados tratados em desconformidade com a lei"
              }
              onChange={handleInfoChange}
            />{" "}
            <label htmlFor="info4">
              Anonimização, bloqueio ou eliminação de dados tratados em
              desconformidade com a lei
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="info5"
              name="informacao"
              value="Vedação de dados pessoais "
              checked={selectedInfo === "Vedação de dados pessoais "}
              onChange={handleInfoChange}
            />{" "}
            <label htmlFor="info5">Vedação de dados pessoais</label>
          </div>
          <p></p>
          <div className="">
            <input
              style={{ width: "1em", height: "1em" }}
              type="checkbox"
              className="form-check-input"
              checked={isChecked}
              onChange={handleCheckboxChange}
              id="checkboxField"
            />
            <label htmlFor="checkboxField" className="form-check-label">
              Ao marcar esta caixa e enviar o formulário, você está de acordo
              com o tratamento dos dados acima informados, nos termos do nosso
              Aviso de Privacidade disponibilizado no site.
            </label>
          </div>
          <p></p>
          {aviso ? (
            <button className="btn btn-success">ENVIAR</button>
          ) : (
            <div className="alert alert-warning">
              É necessário aceitar os termos do nosso Aviso de Privacidade!
            </div>
          )}
          <div className="form-group mt-3 ">
            {loading && (
              <div>
                <img
                  style={{ width: "40px" }}
                  src={loadingGif}
                  alt="Loading gif"
                />
                Enviando...
              </div>
            )}
          </div>
          <div className="mt-3">
            {error && (
              <div className="alert alert-danger" id="erroID">
                {error}
              </div>
            )}
            {message && (
              <div className="alert alert-success" id="messageID">
                {message}
              </div>
            )}
          </div>
          <p className="mt-3"></p>
        </form>
      </div>
    </div>
  );
};
