import React from "react";
import logoEnotariado from "../../imgs/e-notariado.jpeg";
import { Link } from "react-router-dom";

export const ENotariado = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>E-NOTARIADO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-8 col-lg-8 col-sm-12">
            <p>
              <u>
                <h5>O que é?</h5>
              </u>
            </p>
            <strong>
              Plataforma e-Notariado
              <br />
            </strong>
            <p>
              O e-Notariado é a plataforma digital gerida pelo Colégio Notarial
              do Brasil – Conselho Federal, que conecta os usuários aos serviços
              oferecidos pelos cartórios de notas em todo o Brasil.
            </p>
            <strong>
              O que é um ato notarial online
              <br />
            </strong>
            <p>
              A partir da publicação do Provimento nº 100/2020, cidadãos de todo
              o País podem realizar atos notariais de forma online, por meio da
              plataforma e-Notariado, que oferece segurança jurídica e os mesmos
              efeitos de um ato realizado de forma presencial no cartório de
              notas. Todo ato notarial online contará com videoconferência entre
              o requerente e o tabelião, e a assinatura da parte por meio de
              certificado digital.
            </p>
            <p>
              <u>
                <h5>Como fazer?</h5>
              </u>
            </p>
            <strong>Assinatura digital</strong>

            <p>
              Para realizar a assinatura e identificação em um ato eletrônico, é
              necessário ter um certificado digital ICP-Brasil, o mesmo
              utilizado para envio do Imposto de Renda de Pessoa Física (IRPF),
              ou um Certificado Digital e-Notariado, com emissão gratuita e
              validade de dois anos, instalado em seu celular. O Certificado
              Notarizado pode ser emitido em um Cartório de Notas credenciado
              como Autoridade Notarial, com seu documento de identidade e
              comprovante de endereço, ou pela plataforma e-Notariado, por meio
              de videoconferência.
            </p>
            <strong>Videoconferência e assinatura</strong>

            <p>
              Com seu certificado digital, basta fazer login no e-Notariado,
              escolher o serviço desejado e agendar sua videoconferência, que
              será conduzida pelo tabelião de notas, que indicará a abertura da
              gravação, a data e hora do início, e o nome completo dos
              participantes. Ao término do ato, fará a leitura do conteúdo na
              íntegra e colherá manifestação de vontade dos participantes. Os
              documentos serão enviados por e-mail para validação com a
              assinatura digital. Os efeitos do ato são imediatos.
            </p>
            <strong>Autorização Eletrônica de Viagem</strong>

            <p>
              Emita Autorizações Eletrônicas de Viagem de Criança e Adolescente
              com seu certificado digital e faça os reconhecimentos dos
              responsáveis com o Tabelionato de Notas de sua preferência.
            </p>
            <strong>
              Autorização Eletrônica de Doação de Órgãos, Tecidos e Partes do
              Corpo Humano - AEDO
            </strong>

            <p>
              Realize uma autorização eletrônica de doação de órgãos, tecidos e
              partes do corpo humano.
            </p>
            <strong>e-Not Assina</strong>

            <p>
              Crie fluxos de assinaturas de seus próprios documentos, assine-os
              com o certificado notarizado e solicite o reconhecimento das
              assinaturas eletrônicas no cartório emissor.
            </p>
            <strong>Central Nacional de Cartões e Firma</strong>

            <p>
              Localize quais cartórios possuem cartões de firmas de uma pessoa.
            </p>
            <strong>Certificado Digital Notarizado</strong>

            <p>
              Emita seu certificado digital notarizado gratuitamente em um
              cartório credenciado para solicitar e assinar atos notariais.
            </p>
            <p>
              <small>Fonte consultada: </small>
              <Link
                to="https://www.notariado.org.br/e-notariado/"
                target="_blank"
              >
                <small>https://www.notariado.org.br/e-notariado/</small>
              </Link>
            </p>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12 align-items-start justify-content-end">
            <img
              src={logoEnotariado}
              style={{ width: "200px !important" }}
              alt="Logo Enotariado"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
