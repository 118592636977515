import React from "react";
import { Link } from "react-router-dom";

export const AtaNotarialUsucapiao = () => {
  return (
    <div className="margin-top">
      <div className="page-header-ri-background"></div>
      <div className="page-header-ri container">
        <h3>ATA NOTARIAL DE USUCAPIÃO</h3>
        <span className=""></span>
      </div>
      <section className="container quem-somos servicos-inside">
        <div className="row">
          <div class="content-internas col-md-12 col-lg-12 col-sm-12">
            <h5>O que é?</h5>
            <p>
              A usucapião consiste na aquisição da propriedade em favor daquele
              que, durante determinado período de tempo fixado em lei, detém a
              posse justa, sem oposição e com ânimo de permanência de
              determinado bem imóvel.
            </p>
            <p>
              O novo Código de Processo Civil (Lei nº 13.105/15), passou a
              admitir que a usucapião de bens imóveis seja realizada através de
              um procedimento extrajudicial, a ser requerida perante o Oficial
              do Registro de Imóveis, após lavratura de ata notarial pelo
              Tabelionato de Notas.
            </p>
            <p>
              A ata notarial da usucapião é documento obrigatório que visa
              atestar o tempo de posse e a cadeia de eventuais possuidores
              anteriores, assim como as características do bem, a presença do
              possuidor e confrontantes (vizinhos), a depender do caso e das
              circunstâncias.
            </p>
            <p>
              A presença de um advogado não é obrigatória para a realização da
              ata notarial, apenas para o registro perante o Ofício de Imóveis.
              No entanto, é aconselhável que o advogado participe da lavratura
              do ato, assessorando a parte interessada. Também é possível que um
              engenheiro/arquiteto participe do ato, afim de demonstrar a área a
              ser usucapida.
            </p>
            <p>
              <small>Fonte consultada:</small>
              <Link
                to="https://4tabelionatodenotas.com.br/ata-notarial-de-usucapiao/"
                target="_blank"
              >
                <small>
                  https://4tabelionatodenotas.com.br/ata-notarial-de-usucapiao/
                </small>
              </Link>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
