import "./App.css";
import { Navbar } from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import { Home } from "./views/Home";
import { QuemSomos } from "./views/QuemSomos";
import { CustasEmolumentos } from "./views/CustasEmolumentos";
import { ModeloRequerimentos } from "./views/ModeloRequerimentos";
import { ENotariado } from "./views/servicos/ENotariado";
import { Nascimento } from "./views/servicos/Nascimento";
import { Casamento } from "./views/servicos/Casamento";
import { EscrituraPublica } from "./views/servicos/EscrituraPublica";
import { Procuracao } from "./views/servicos/Procuracao";
import { Apostilamento } from "./views/servicos/Apostilamento";
import { Autenticacao } from "./views/servicos/Autenticacao";
import { ReconhecimentoFirma } from "./views/servicos/ReconhecimentoFirma";
import { Footer } from "./components/Footer";
import CookiesAlert from "./components/CookiesAlert";
import { Certidoes } from "./views/servicos/Certidoes";
import { RetificacaoRc } from "./views/servicos/RetificacaoRc";
import { ReconhecimentoPaternidade } from "./views/servicos/ReconhecimentoPaternidade";
import { Obito } from "./views/servicos/Obito";
import { EditalProclamas } from "./views/servicos/EditalProclamas";
import { EscrituraPublicaDoacao } from "./views/servicos/EscrituraPublicaDoacao";
import { DeclaracaoUniaoEstavel } from "./views/servicos/DeclaracaoUniaoEstavel";
import { AtaNotarial } from "./views/servicos/AtaNotarial";
import { AtaNotarialUsucapiao } from "./views/servicos/AtaNotarialUsucapiao";
import { Divorcio } from "./views/servicos/Divorcio";
import { RevogacaoProcuracao } from "./views/servicos/RevogacaoProcuracao";
import { Inventario } from "./views/servicos/Inventario";
import { Testamento } from "./views/servicos/Testamento";
import { MaloteVirtual } from "./views/servicos/MaloteVirtual";
import { Emancipacao } from "./views/servicos/Emancipacao";
import { Substabelecimento } from "./views/servicos/Substabelecimento";
import { FaleConosco } from "./views/FaleConosco";
import { LgpdForm } from "./views/LgpdForm";
import { AvisodePrivacidade } from "./AvisodePrivacidade";

import { useState } from "react";
import { NotFound } from "./views/NotFound";
import { AvisodePrivacidadeCandidatos } from "./views/AvisodePrivacidadeCandidatos";
import { Prazos } from "./views/Prazos";

function App() {
  const [message, setMessage] = useState();
  return (
    <div className="App">
      <Navbar />
      <CookiesAlert />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/quem-somos/" element={<QuemSomos />} />
        <Route path="/custas-e-emolumentos/" element={<CustasEmolumentos />} />
        <Route
          path="/modelos-requerimentos/"
          element={<ModeloRequerimentos />}
        />
        <Route path="/servicos/e-notariado/" element={<ENotariado />} />
        <Route path="/servicos/nascimento/" element={<Nascimento />} />
        <Route path="/servicos/casamento/" element={<Casamento />} />
        <Route
          path="/servicos/escritura-publica/"
          element={<EscrituraPublica />}
        />
        <Route path="/servicos/procuracao/" element={<Procuracao />} />
        <Route
          path="/servicos/apostilamento-haia/"
          element={<Apostilamento />}
        />
        <Route path="/servicos/autenticacao/" element={<Autenticacao />} />
        <Route path="/servicos/certidoes/" element={<Certidoes />} />
        <Route
          path="/servicos/retificacao-de-registro-civil/"
          element={<RetificacaoRc />}
        />
        <Route
          path="/servicos/reconhecimento-de-paternidade/"
          element={<ReconhecimentoPaternidade />}
        />
        <Route path="/servicos/obito/" element={<Obito />} />
        <Route
          path="/servicos/edital-de-proclamas/"
          element={<EditalProclamas />}
        />
        <Route
          path="/servicos/escritura-publica-de-doacao/"
          element={<EscrituraPublicaDoacao />}
        />
        <Route
          path="/servicos/declaracao-de-uniao-estavel/"
          element={<DeclaracaoUniaoEstavel />}
        />
        <Route
          path="/servicos/reconhecimento-de-firma/"
          element={<ReconhecimentoFirma />}
        />
        <Route path="/servicos/ata-notarial/" element={<AtaNotarial />} />
        <Route
          path="/servicos/ata-notarial-usucapiao/"
          element={<AtaNotarialUsucapiao />}
        />
        <Route path="/servicos/divorcio/" element={<Divorcio />} />
        <Route
          path="/servicos/revogacao-de-procuracao/"
          element={<RevogacaoProcuracao />}
        />
        <Route path="/servicos/inventario/" element={<Inventario />} />
        <Route path="/servicos/testamento/" element={<Testamento />} />
        <Route path="/servicos/malote-virtual/" element={<MaloteVirtual />} />
        <Route path="/servicos/emancipacao/" element={<Emancipacao />} />
        <Route
          path="/fale-conosco/"
          element={<FaleConosco message={message} setMessage={setMessage} />}
        />
        <Route
          path="/servicos/substabelecimento/"
          element={<Substabelecimento />}
        />
        <Route
          path="/solicitacao-dados/"
          element={<LgpdForm message={message} setMessage={setMessage} />}
        />
        <Route path="/politica-privacidade" element={<AvisodePrivacidade />} />
        <Route
          path="/politica-privacidade-candidatos"
          element={<AvisodePrivacidadeCandidatos />}
        />
        <Route path="/prazos/" element={<Prazos />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
